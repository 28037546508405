import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { putUserRequest } from '../../Redux/actions';
import {
  UnderlineTitle,
  Loading,
  MainTextField,
  InputPhone,
} from '@emisys/audience-sdk-ui-react';
import Select from 'react-select';
import { genreList, SelectedGenre } from '../../components/Genre/Genre';
import DatePickerComponent from '../../components/DatePicker/DatePicker';
import { checkReduxResponse } from '../../services/checkReduxResponse';
import { getNameLanguage } from '../../services/GetNameLanguage';
import { currentProject } from '../../Global/currentProject';
import Translator from '../../services/translator';
import { storeType } from '../../index';
import ButtonsSaveModal from '../../components/Buttons/ButtonsSaveModal';

const PersonalContent = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);
  const [userDetails, setUserDetails] = useState<any>({});
  const allowedLanguages = currentProject.allowedLanguages;
  const userSelfDetails = useSelector(
    (state: storeType) => state.user.userSelfDetails
  );
  const userUpdate = useSelector((state: storeType) => state.user.userUpdate);

  const putUser = useCallback(() => {
    dispatch(putUserRequest(userDetails));
  }, [dispatch, userDetails]);

  useEffect(() => {
    setLoading(userSelfDetails.loading);

    if (checkReduxResponse(userSelfDetails, 'details')) {
      handleSetUser(userSelfDetails.details);
    }
  }, [userSelfDetails]);

  useEffect(() => {
    if (userUpdate) {
      setLoadingSend(userUpdate.loading);
    }
  }, [userUpdate]);

  const handleSetUser = (userDetails: any) => {
    setUserDetails(userDetails);
  };

  const handleChange = (name: string) => (event: any) => {
    const copyUserDetails: any = { ...userDetails };
    if (name === 'phonenumber') {
      if (event !== '') {
        copyUserDetails[name] = '+' + event;
      } else {
        copyUserDetails[name] = null;
      }
    } else {
      copyUserDetails[name] = event.target.value;
    }

    setUserDetails(copyUserDetails);
  };

  const handleChangeSelect = (name: string, selectedItem: any) => {
    const copyUserDetails: any = { ...userDetails };
    copyUserDetails[name] = selectedItem ? selectedItem.value : '';

    setUserDetails(copyUserDetails);
  };

  const handleChangeDate = (date: Date | null) => {
    const copyUserDetails: any = { ...userDetails };
    copyUserDetails.birthday = date;

    setUserDetails(copyUserDetails);
  };

  return (
    <>
      <div className="col-12">
        <UnderlineTitle title={Translator.trans('account.general.info')} />
        <div className="information">
          <div className="col-12 col-lg-4 p-0">
            <h4>{Translator.trans('appModule.email')}</h4>
          </div>
          <div className="col-12 col-lg-8 p-0">
            <MainTextField
              disabled
              value={userDetails?.email}
              onChange={handleChange('email')}
            />
          </div>
        </div>
        <div className="information">
          <div className="col-12 col-lg-4 p-0">
            <h4>{Translator.trans('appModule.first.name')}</h4>
          </div>
          <div className="col-12 col-lg-8 p-0">
            <MainTextField
              value={userDetails?.firstName}
              onChange={handleChange('firstName')}
            />
          </div>
        </div>
        <div className="information">
          <div className="col-12 col-lg-4 p-0">
            <h4>{Translator.trans('appModule.last.name')}</h4>
          </div>
          <div className="col-12 col-lg-8 p-0">
            <MainTextField
              value={userDetails?.lastName}
              onChange={handleChange('lastName')}
            />
          </div>
        </div>
        <div className="information">
          <div className="col-12 col-lg-4 p-0">
            <h4>{Translator.trans('appModule.gender')}</h4>
          </div>
          <div className="col-12 col-lg-8 p-0">
            <Select
              isClearable
              value={
                userDetails?.gender
                  ? {
                      value: userDetails?.gender.value || userDetails?.gender,
                      label: SelectedGenre(
                        userDetails?.gender.value || userDetails?.gender
                      ),
                    }
                  : ''
              }
              onChange={(text: any) => handleChangeSelect('gender', text)}
              options={genreList}
            />
          </div>
        </div>
        <div className="information">
          <div className="col-12 col-lg-4 p-0">
            <h4>{Translator.trans('appModule.languages')}</h4>
          </div>
          <div className="col-12 col-lg-8 p-0">
            <Select
              isClearable
              isLoading={!allowedLanguages}
              value={
                userDetails?.defaultLanguage
                  ? {
                      value:
                        userDetails?.defaultLanguage.value ||
                        userDetails?.defaultLanguage,
                      label: userDetails?.defaultLanguage.value
                        ? getNameLanguage(
                            currentProject.language.locale,
                            userDetails?.defaultLanguage.value
                          )
                        : getNameLanguage(
                            currentProject.language.locale,
                            userDetails?.defaultLanguage
                          ),
                    }
                  : ''
              }
              onChange={(text: any) =>
                handleChangeSelect('defaultLanguage', text)
              }
              options={
                allowedLanguages &&
                allowedLanguages.map((language: string) => ({
                  value: language,
                  label: getNameLanguage(
                    currentProject.language.locale,
                    language
                  ),
                }))
              }
            />
          </div>
        </div>
        <div className="information">
          <div className="col-12 col-lg-4 p-0">
            <h4>{Translator.trans('appModule.birthday')}</h4>
          </div>
          <div className="col-12 col-lg-8 p-0">
            <DatePickerComponent
              maxDate={new Date()}
              selected={
                userDetails?.birthday ? new Date(userDetails?.birthday) : null
              }
              onChange={handleChangeDate}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>
        </div>
        <div className="information">
          <div className="col-12 col-lg-4 mt-2  p-0">
            <h4>{Translator.trans('appModule.phone')}</h4>
          </div>
          <div className="col-12 col-lg-8  p-0">
            <InputPhone
              country={currentProject.defaultCountry.toLowerCase()}
              value={userDetails?.phonenumber}
              onChange={handleChange('phonenumber')}
            />
          </div>
        </div>
      </div>
      <ButtonsSaveModal
        isLoading={loadingSend}
        onSubmit={() => putUser()}
        hideCancel
      />

      {loading && <Loading />}
    </>
  );
};

export default PersonalContent;
