import React from 'react';
import { BlockPicker, TwitterPicker } from 'react-color';
import './ColorPicker.css';

const colorsPickers = [
  '#1abc9c',
  '#2ecc71',
  '#3498db',
  '#9b59b6',
  '#34495e',
  '#f39c12',
  '#d35400',
  '#c0392b',
  '#bdc3c7',
  '#7f8c8d',
];

export const ColorPicker = (props: {
  label?: string;
  color: string;
  changeColor: (hex: string) => void;
}) => (
  <div className={'color-picker-container'}>
    {props.label && <span>{props.label}</span>}
    <BlockPicker
      color={props.color}
      onChange={(colorPicker) => props.changeColor(colorPicker.hex)}
      colors={colorsPickers}
    />
  </div>
);

export const ColorPickerBox = (props: {
  color: string;
  changeColor: (hex: string) => void;
  show: boolean;
  onClick: () => void;
}) => (
  <div>
    <div
      onClick={props.onClick}
      className={'color-box-picker'}
      style={{ backgroundColor: props.color }}
    />
    <TwitterPicker
      className={'color-picker-box'}
      styles={{
        default: { card: { display: props.show ? 'block' : 'none' } },
      }}
      color={props.color}
      onChange={(colorPicker) => {
        props.changeColor(colorPicker.hex);
        props.onClick();
      }}
      colors={colorsPickers}
    />
  </div>
);
