import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Translator from '../../../services/translator';
import { NotificationManager } from '../../../components/Notification/NotificationManager';
import {
  getContentNewsRequest,
  getContentNewsTranslationRequest,
  postUploadPictureRequest,
  putContentNewsRequest,
  putContentNewsTranslationRequest,
  clearUploadPicture,
} from '../../../Redux/actions';
import { UnderlineTitle, HeaderBanner } from '@emisys/audience-sdk-ui-react';
import { currentProject } from '../../../Global/currentProject';
import { pagesConstant } from '../../../Constants/Constant';
import AccordionTranslation from '../../../components/Accordion/AccordionTranslation';
import { checkReduxResponse } from '../../../services/checkReduxResponse';
import { storeType } from '../../../index';
import NewsModel from '../../../Models/NewsModel';
import { MenuBook } from '@mui/icons-material';
import ButtonsSaveModal from '../../../components/Buttons/ButtonsSaveModal';
import NewsTranslations from '../NewsContent/NewsTranslations';
import NewsContent from '../NewsContent/NewsContent';

const NewsEdit = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [news, setNews] = useState<NewsModel>(new NewsModel({}));
  const [loadingSend, setLoadingSend] = useState(false);
  const [translationContentNews, setTranslationContentNews] = useState<any>([]);

  const translationContentNewsList = useSelector(
    (state: storeType) => state.contentNews.translationContentNewsList
  );
  const newUploadPicture = useSelector(
    (state: storeType) => state.uploads.newUploadPicture
  );

  const contentNewsDetails = useSelector(
    (state: storeType) => state.contentNews.contentNewsDetails
  );
  const updatedContentNews = useSelector(
    (state: storeType) => state.contentNews.updatedContentNews
  );

  const translationContentNewsRef = useRef<any>([]);

  const newsRef: any = useRef();
  const newsImage: any = useRef(null);

  useEffect(() => {
    if (id) {
      dispatch(getContentNewsTranslationRequest(id));
      dispatch(getContentNewsRequest(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (checkReduxResponse(contentNewsDetails, 'contentNews')) {
      newsRef.current = contentNewsDetails.contentNews;
      setNews(newsRef.current);
    }
  }, [contentNewsDetails]);

  useEffect(() => {
    if (checkReduxResponse(translationContentNewsList, 'contentNewsList')) {
      translationContentNewsRef.current = [];
      currentProject.allowedLanguages.forEach((language) => {
        const currentTrad = translationContentNewsList.contentNewsList.find(
          (element: any) => element.locale === language
        );
        if (currentTrad) {
          translationContentNewsRef.current.push(currentTrad);
        } else {
          translationContentNewsRef.current.push({
            locale: language,
            title: '',
            teaser: '',
            content: '',
            externalLinkLabel: '',
            externalLinkUrl: '',
          });
        }
      });
      setTranslationContentNews(translationContentNewsRef.current);
    }
  }, [translationContentNewsList]);

  const updateNews = useCallback(
    (newPictureId?: number) => {
      const currentTrad = translationContentNews.find(
        (element: any) => element.locale === currentProject.language.locale
      );

      if (newPictureId !== undefined) {
        newsRef.current.picture = newPictureId;
      } else {
        delete newsRef.current.picture;
      }

      if (currentTrad?.title) {
        dispatch(putContentNewsRequest(newsRef.current));
        dispatch(
          putContentNewsTranslationRequest(newsRef.current.id, {
            translations: translationContentNewsRef.current,
          })
        );
      } else {
        NotificationManager.warning(Translator.trans('message.title.missing'));
      }
    },
    [dispatch, translationContentNews]
  );

  useEffect(() => {
    if (newUploadPicture) {
      setLoadingSend(newUploadPicture.loading);
      if (checkReduxResponse(newUploadPicture, 'uploadPicture')) {
        updateNews(newUploadPicture.uploadPicture.id);
        dispatch(clearUploadPicture());
      }
    }
  }, [dispatch, navigate, newUploadPicture, updateNews]);

  useEffect(() => {
    if (updatedContentNews) {
      setLoadingSend(updatedContentNews.loading);
      if (checkReduxResponse(updatedContentNews, 'contentNews')) {
        navigate(pagesConstant.news.home);
      }
    }
  }, [navigate, updatedContentNews]);

  const handleChangePicture = (picture: string | null) => {
    if (picture === null) {
      newsImage.current = null;
      newsRef.current.picture = null;
      setNews(newsRef.current);
    } else {
      newsImage.current = picture;
    }
  };

  const handleChangeTranslation = (name: string, data: any, locale: string) => {
    let objIndex = translationContentNews.findIndex(
      (item: any) => item.locale === locale
    );
    translationContentNewsRef.current[objIndex][name] = data;
    setTranslationContentNews(translationContentNewsRef.current);
  };

  const handleChangeNews = (name: string, data: any) => {
    if (name === 'publishDate') {
      newsRef.current.publishDate = data;
    }
    setNews(newsRef.current);
  };

  const handleSubmit = useCallback(() => {
    if (newsImage.current) {
      dispatch(
        postUploadPictureRequest({
          type: 'PictureNews',
          project_id: currentProject.id,
          data: newsImage.current,
        })
      );
    } else {
      updateNews();
    }
  }, [dispatch, updateNews]);

  return (
    <div className="app-wrapper div-wrapper-title">
      <div className="headerAlign">
        <HeaderBanner
          title={Translator.trans('news.edit.title')}
          icon={<MenuBook />}
        />
      </div>
      <div className="row">
        <div className="col-12">
          <div className="jr-card">
            <UnderlineTitle
              title={Translator.trans('appModule.general.info')}
            />
            {currentProject.allowedLanguages.map((language) => {
              let translation = translationContentNews.find(
                (element: any) => element.locale === language
              );

              return (
                <AccordionTranslation key={language} language={language}>
                  <NewsTranslations
                    translation={translation}
                    handleChangeTranslation={handleChangeTranslation}
                    language={language}
                  />
                </AccordionTranslation>
              );
            })}

            <NewsContent
              news={news}
              handleChangeNews={handleChangeNews}
              handleChangePicture={handleChangePicture}
            />

            <ButtonsSaveModal
              onClose={() => navigate(pagesConstant.news.home)}
              onSubmit={handleSubmit}
              isLoading={loadingSend}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsEdit;
