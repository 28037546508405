import React, { PropsWithChildren } from 'react';
import '../Plan.css';

interface DragTargetProps extends PropsWithChildren {
  onDropListItem: (
    x: number,
    y: number,
    store: string | null,
    place: string | null
  ) => void;
  onDropCat: (type: string, x: number, y: number) => void;
  onPinMove: (id: string, x: number, y: number) => void;
  onImageClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  sizeImg: {
    width: number;
    height: number;
  };
}

const DragTarget = (props: DragTargetProps) => {
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    const rect = e.currentTarget.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * props.sizeImg.width;
    const y = ((e.clientY - rect.top) / rect.height) * props.sizeImg.height;

    const markerId = e.dataTransfer.getData('marker-id');
    if (markerId) {
      props.onPinMove(markerId, x, y);
      return;
    }

    const storeId = e.dataTransfer.getData('storeId');
    if (storeId) {
      props.onDropListItem(x, y, storeId, null);
      return;
    }

    const placeId = e.dataTransfer.getData('placeId');
    if (placeId) {
      props.onDropListItem(x, y, null, placeId);
      return;
    }

    const catType = e.dataTransfer.getData('cat-type');
    if (catType) {
      props.onDropCat(catType, x, y);
      return;
    }
  };

  return (
    <div
      onClick={props.onImageClick}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      className={'drag-target-container'}
    >
      {props.children}
    </div>
  );
};

export default DragTarget;
