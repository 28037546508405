import React, { useState, useEffect } from 'react';
import DraggableItem from './DraggableItem';
import Translator from '../../../services/translator';
import StoreModel from '../../../Models/StoreModel';
import MapPointerModel from '../../../Models/MapPointerModel';
import { del, getUrl } from '../../../Redux/services';
import { NotificationManager } from '../../../components/Notification/NotificationManager';
import PlanCategoryModel from '../../../Models/PlanCategoryModel';
import { Loading } from '@emisys/audience-sdk-ui-react';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { getCurrentTranslation } from '../../../services/GetCurrentTranslation';
import { currentProject } from '../../../Global/currentProject';
import '../Plan.css';
import PlaceModel from '../../../Models/PlaceModel';

interface ItemListProps {
  stores: StoreModel[];
  places: PlaceModel[];
  markersItems: MapPointerModel[];
  planCategories: PlanCategoryModel[];
  setMarkersItems: (marker: MapPointerModel[]) => void;
  onItemSelect: (item: MapPointerModel | null) => void;
  selectedItem: MapPointerModel | null;
}

const ItemList = ({
  stores,
  places,
  markersItems,
  setMarkersItems,
  onItemSelect,
  selectedItem,
  planCategories,
}: ItemListProps) => {
  const locale = currentProject.language.locale;
  const [allItems, setAllItems] = useState<MapPointerModel[] | null>(null);
  const [showItems, setShowItems] = useState<MapPointerModel[] | null>(null);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setSearchValue('');
    let availableStores = stores
      .filter(
        (store: StoreModel) =>
          !markersItems.some((marker) => marker.store === store.iri)
      )
      .map((store: StoreModel) => {
        const storeName =
          getCurrentTranslation(store.translations, locale) ?? store;
        return new MapPointerModel({
          store: store.iri,
          displayName: storeName.name,
        });
      });

    let availablePlaces = places
      .filter(
        (place: PlaceModel) =>
          !markersItems.some((marker) => marker.place === place.iri)
      )
      .map((place: PlaceModel) => {
        const placeName =
          getCurrentTranslation(place.translations, locale).name ?? '';

        return new MapPointerModel({
          place: place.iri,
          displayName: placeName,
        });
      });

    const newMarkersItems = [
      ...markersItems,
      ...availableStores,
      ...availablePlaces,
    ];

    availablePlaces = newMarkersItems.filter(
      (item) => item.offset_x === 0 && item.offset_y === 0
    );

    setAllItems(availablePlaces);
    setShowItems(availablePlaces);
  }, [stores, places, markersItems, locale]);

  const deletePosition = (id: number) => {
    const find = markersItems.find((marker) => marker.id === id);

    if (find) {
      setMarkersItems(markersItems.filter((marker) => marker.id !== id));

      del(getUrl('content/v3/map-items/' + id, null, ''))
        .catch(() => {
          markersItems.push(find);
          setMarkersItems([...markersItems]);
          NotificationManager.error(Translator.trans('plan.del.marker.error'));
        })
        .finally(() => onItemSelect(null));
    } else {
      NotificationManager.error(Translator.trans('plan.del.marker.error'));
    }
  };

  const onSearch = (value: string) => {
    setSearchValue(value);

    if (value !== '' && allItems) {
      const filteredList = allItems.filter((item: MapPointerModel) =>
        currentProject.stringMatcher.searchNameForMatch(item.displayName, value)
      );
      setShowItems(filteredList);
    } else {
      setShowItems(allItems);
    }
  };

  return (
    <div className="item-list-container">
      {showItems ? (
        showItems.length > 0 ? (
          <>
            <TextField
              className="item-list-search"
              placeholder={Translator.trans('plan.search.placeholder')}
              type="search"
              size="small"
              fullWidth
              value={searchValue}
              onChange={(e) => {
                onSearch(e.target.value);
              }}
              InputProps={{
                endAdornment: <SearchIcon />,
              }}
            />
            <ul>
              {showItems.map((item: MapPointerModel, index) => {
                const category = planCategories?.find(
                  (cate) => cate.iri === item.category
                );

                return (
                  <DraggableItem
                    key={index}
                    item={item}
                    currentCategory={category}
                    isSelected={
                      selectedItem?.store
                        ? selectedItem.store === item.store
                        : selectedItem?.place
                        ? selectedItem.place === item.place
                        : selectedItem?.id === item.id
                    }
                    onSelect={onItemSelect}
                    onDelete={deletePosition}
                  />
                );
              })}
            </ul>
          </>
        ) : (
          <p className="empty-list-item">
            {Translator.trans('plan.empty.list')}
          </p>
        )
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default ItemList;
