import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  clearAppMobile,
  getAppMobileInfoRequest,
  postUploadPictureRequest,
  putAppMobileInfoRequest,
} from '../../Redux/actions';
import { NotificationManager } from '../../components/Notification/NotificationManager';
import Translator from '../../services/translator';
import { currentProject } from '../../Global/currentProject';
import { checkReduxResponse } from '../../services/checkReduxResponse';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import DatePickerComponent from '../../components/DatePicker/DatePicker';
import {
  SwitchComponent,
  UnderlineTitle,
  InputPhone,
  DragAndDrop,
  HeaderBanner,
  MainButton,
  MainTextField,
} from '@emisys/audience-sdk-ui-react';
import { storeType } from '../../index';
import AppMobileModel from '../../Models/AppMobileModel';
import { ColorPicker } from '../../components/ColorPicker/ColorPicker';
import './Config.css';
import DeleteBadge from '../../components/DeleteBadge/DeleteBadge';

type imageType = {
  id: number | null;
  original: string;
};

const Config = () => {
  const dispatch = useDispatch();
  const [loadingSend, setLoadingSend] = useState(false);
  const [appMobileUrlTicketing, setAppMobileUrlTicketing] = useState('');
  const [appMobileUrlScheme, setAppMobileUrlScheme] = useState('');
  const [emergencyPhonenumber, setEmergencyPhonenumber] = useState('');
  const [countdownDatetime, setCountdownDatetime] = useState<Date | null>(null);

  const [appMobileShowCashless, setAppMobileShowCashless] = useState(false);
  const [appMobileShowNews, setAppMobileShowNews] = useState(false);
  const [appMobileShowInfo, setAppMobileShowInfo] = useState(false);
  const [appMobileShowArtist, setAppMobileShowArtist] = useState(false);
  const [appMobileShowLineup, setAppMobileShowLineup] = useState(false);
  const [appMobileShowTicketing, setAppMobileShowTicketing] = useState(false);
  const [appMobileShowETicket, setAppMobileShowETicket] = useState(false);
  const [appMobileShowPos, setAppMobileShowPos] = useState(false);
  const [appMobileShowCountdown, setAppMobileShowCountdown] = useState(false);
  const [colorBackground, setColorBackground] = useState('');
  const [colorBackgroundLight, setColorBackgroundLight] = useState('');
  const [colorPrimary, setColorPrimary] = useState('');
  const [colorPrimaryDark, setColorPrimaryDark] = useState('');
  const [colorSecondary, setColorSecondary] = useState('');

  const [pictureLogo, setPictureLogo] = useState<imageType | null>(null);
  const [pictureBackground, setPictureBackground] = useState<imageType | null>(
    null
  );
  const [picturePartners, setPicturePartners] = useState<imageType | null>(
    null
  );
  const uploadedPicture = useRef('');

  const switchValues = useMemo(
    () => [
      {
        label: Translator.trans('config.label.cashless'),
        value: appMobileShowCashless,
        onChange: (value: boolean) => setAppMobileShowCashless(value),
      },
      {
        label: Translator.trans('config.label.news'),
        value: appMobileShowNews,
        onChange: (value: boolean) => setAppMobileShowNews(value),
      },
      {
        label: Translator.trans('config.label.infos'),
        value: appMobileShowInfo,
        onChange: (value: boolean) => setAppMobileShowInfo(value),
      },
      {
        label: Translator.trans('config.label.artists'),
        value: appMobileShowArtist,
        onChange: (value: boolean) => setAppMobileShowArtist(value),
      },
      {
        label: Translator.trans('config.label.lineup'),
        value: appMobileShowLineup,
        onChange: (value: boolean) => setAppMobileShowLineup(value),
      },
      {
        label: Translator.trans('config.label.ticketing'),
        value: appMobileShowTicketing,
        onChange: (value: boolean) => setAppMobileShowTicketing(value),
      },
      {
        label: Translator.trans('config.label.etickets'),
        value: appMobileShowETicket,
        onChange: (value: boolean) => setAppMobileShowETicket(value),
      },
      {
        label: Translator.trans('config.label.pos'),
        value: appMobileShowPos,
        onChange: (value: boolean) => setAppMobileShowPos(value),
      },
      {
        label: Translator.trans('config.label.countdown'),
        value: appMobileShowCountdown,
        onChange: (value: boolean) => {
          setAppMobileShowCountdown(value);
          if (!value) {
            setCountdownDatetime(null);
          } else {
            setCountdownDatetime(new Date());
          }
        },
        isCountdown: true,
      },
    ],
    [
      appMobileShowCashless,
      appMobileShowNews,
      appMobileShowInfo,
      appMobileShowArtist,
      appMobileShowLineup,
      appMobileShowTicketing,
      appMobileShowETicket,
      appMobileShowPos,
      appMobileShowCountdown,
    ]
  );

  const appMobileList = useSelector(
    (state: storeType) => state.appMobile.appMobileList
  );

  const newAppMobileList = useSelector(
    (state: storeType) => state.appMobile.newAppMobileList
  );

  const newUploadPicture = useSelector(
    (state: storeType) => state.uploads.newUploadPicture
  );

  useEffect(() => {
    dispatch(getAppMobileInfoRequest(currentProject.id));
  }, [dispatch]);

  useEffect(() => {
    if (checkReduxResponse(appMobileList, 'appMobile')) {
      const appMobile: AppMobileModel = appMobileList.appMobile;
      setAppMobileUrlTicketing(appMobile.appMobileUrlTicketing);
      setAppMobileUrlScheme(appMobile.appMobileUrlScheme);
      setEmergencyPhonenumber(appMobile.emergencyPhonenumber);
      setCountdownDatetime(appMobile.countdownDatetime);
      setAppMobileShowCashless(appMobile.appMobileShowCashless);
      setAppMobileShowNews(appMobile.appMobileShowNews);
      setAppMobileShowInfo(appMobile.appMobileShowInfo);
      setAppMobileShowArtist(appMobile.appMobileShowArtist);
      setAppMobileShowLineup(appMobile.appMobileShowLineup);
      setAppMobileShowTicketing(appMobile.appMobileShowTicketing);
      setAppMobileShowETicket(appMobile.appMobileShowETicket);
      setAppMobileShowPos(appMobile.appMobileShowPos);
      setAppMobileShowCountdown(appMobile.appMobileShowCountdown);
      setColorBackground(appMobile.colorBackground);
      setColorBackgroundLight(appMobile.colorBackgroundLight);
      setColorPrimary(appMobile.colorPrimary);
      setColorPrimaryDark(appMobile.colorPrimaryDark);
      setColorSecondary(appMobile.colorSecondary);

      if (appMobile.pictureLogo) {
        setPictureLogo({
          id: appMobile.pictureLogo.id,
          original: appMobile.pictureLogo.original,
        });
      }

      if (appMobile.pictureBackground) {
        setPictureBackground({
          id: appMobile.pictureBackground.id,
          original: appMobile.pictureBackground.original,
        });
      }

      if (appMobile.picturePartners) {
        setPicturePartners({
          id: appMobile.picturePartners.id,
          original: appMobile.picturePartners.original,
        });
      }
    }
  }, [appMobileList]);

  useEffect(() => {
    if (newAppMobileList) {
      setLoadingSend(newAppMobileList.loading);
      if (checkReduxResponse(newAppMobileList, 'appMobile')) {
        NotificationManager.success(
          Translator.trans('config.message.success.updated')
        );
        dispatch(clearAppMobile());
      }

      if (newAppMobileList.error) {
        NotificationManager.error(
          Translator.trans(newAppMobileList.error.message)
        );
        dispatch(clearAppMobile());
      }
    }
  }, [dispatch, newAppMobileList]);

  const handleSubmit = () => {
    dispatch(
      putAppMobileInfoRequest(currentProject.id, {
        appMobileUrlTicketing: appMobileUrlTicketing,
        appMobileUrlScheme: appMobileUrlScheme,
        emergencyPhonenumber: emergencyPhonenumber,
        countdownDatetimeUTC: countdownDatetime,
        appMobileShowCashless: appMobileShowCashless,
        appMobileShowNews: appMobileShowNews,
        appMobileShowInfo: appMobileShowInfo,
        appMobileShowArtist: appMobileShowArtist,
        appMobileShowLineup: appMobileShowLineup,
        appMobileShowTicketing: appMobileShowTicketing,
        appMobileShowETicket: appMobileShowETicket,
        appMobileShowPos: appMobileShowPos,
        appMobileShowCountdown: appMobileShowCountdown,
        colorBackground: colorBackground,
        colorBackgroundLight: colorBackgroundLight,
        colorPrimary: colorPrimary,
        colorPrimaryDark: colorPrimaryDark,
        colorSecondary: colorSecondary,
        pictureLogo: pictureLogo?.id ? pictureLogo.id : null,
        pictureBackground: pictureBackground?.id ? pictureBackground.id : null,
        picturePartners: picturePartners?.id ? picturePartners.id : null,
      })
    );
  };

  useEffect(() => {
    if (checkReduxResponse(newUploadPicture, 'uploadPicture')) {
      switch (uploadedPicture.current) {
        case 'pictureLogoName':
          setPictureLogo(newUploadPicture.uploadPicture);
          break;
        case 'pictureBackgroundName':
          setPictureBackground(newUploadPicture.uploadPicture);
          break;
        case 'picturePartnersName':
          setPicturePartners(newUploadPicture.uploadPicture);
          break;
      }
    }
  }, [newUploadPicture]);

  function deletePicture(name: string) {
    if (name === 'deleteLogo') {
      setPictureLogo(null);
    }
    if (name === 'deleteBackground') {
      setPictureBackground(null);
    }
    if (name === 'deletePartners') {
      setPicturePartners(null);
    }
  }

  function onSelectFile(image: any) {
    uploadedPicture.current = image.name;
    if (image.name === 'pictureLogoName') {
      setPictureLogo({
        id: null,
        original: image.src,
      });
    }
    if (image.name === 'pictureBackgroundName') {
      setPictureLogo({
        id: null,
        original: image.src,
      });
    }
    if (image.name === 'picturePartnersName') {
      setPicturePartners({
        id: null,
        original: image.src,
      });
    }

    dispatch(
      postUploadPictureRequest({
        type: 'PictureCommon',
        project_id: currentProject.id,
        data: image.src,
      })
    );
  }

  return (
    <div className="app-wrapper div-wrapper-title">
      <div className="headerAlign">
        <HeaderBanner
          title={Translator.trans('config.title')}
          icon={<SettingsApplicationsIcon />}
        />
      </div>
      <div className="row">
        <div className="col-12">
          <div className="jr-card">
            <UnderlineTitle
              title={Translator.trans('config.subtitle.config')}
            />
            <div className="mb-4">
              <div className={'mb-3'}>
                <MainTextField
                  labelText={Translator.trans('config.label.url.label')}
                  value={appMobileUrlTicketing || ''}
                  onChange={(event) =>
                    setAppMobileUrlTicketing(event.target.value)
                  }
                />
              </div>
              <div className={'mb-3'}>
                <MainTextField
                  labelText={Translator.trans('config.label.scheme')}
                  value={appMobileUrlScheme || ''}
                  onChange={(event) =>
                    setAppMobileUrlScheme(event.target.value)
                  }
                />
              </div>
              <div className={'mb-3'}>
                <div className="mb-1">
                  {Translator.trans('appModule.phone')}
                </div>
                <InputPhone
                  country={currentProject.defaultCountry.toLowerCase()}
                  onChange={(value: string) => setEmergencyPhonenumber(value)}
                  value={emergencyPhonenumber}
                />
              </div>
            </div>

            <UnderlineTitle
              title={Translator.trans('config.subtitle.modules')}
            />
            <div className={'config-content-container mb-4'}>
              {switchValues.map((data: any, index: number) => (
                <div key={index} className={'config-content-container-item'}>
                  <div className={'config-content-container-info'}>
                    <h4 className={'config-content-info'}>{data.label}</h4>

                    <SwitchComponent
                      onHandleChange={data.onChange}
                      checked={data.value}
                    />
                  </div>

                  {data.isCountdown && data.value && (
                    <DatePickerComponent
                      labelText={Translator.trans(
                        'config.label.countdown.label'
                      )}
                      selected={countdownDatetime}
                      onChange={setCountdownDatetime}
                      showTimeSelect
                    />
                  )}
                </div>
              ))}
            </div>

            <UnderlineTitle
              title={Translator.trans('branding.subtitle.color')}
            />
            <div className="row mb-4">
              <div className="col-6 col-md-auto mb-2">
                <ColorPicker
                  label={Translator.trans('branding.primary.color')}
                  color={colorPrimary}
                  changeColor={setColorPrimary}
                />
              </div>
              <div className="col-6 col-md-auto mb-2">
                <ColorPicker
                  label={Translator.trans('branding.primary.color.dark')}
                  color={colorPrimaryDark}
                  changeColor={setColorPrimaryDark}
                />
              </div>
              <div className="col-6 col-md-auto mb-2">
                <ColorPicker
                  label={Translator.trans('branding.secondary.color')}
                  color={colorSecondary}
                  changeColor={setColorSecondary}
                />
              </div>
              <div className="col-6 col-md-auto mb-2">
                <ColorPicker
                  label={Translator.trans('branding.background.color')}
                  color={colorBackground}
                  changeColor={setColorBackground}
                />
              </div>
              <div className="col-6 col-md-auto mb-2">
                <ColorPicker
                  label={Translator.trans('branding.background.color.light')}
                  color={colorBackgroundLight}
                  changeColor={setColorBackgroundLight}
                />
              </div>
            </div>

            <UnderlineTitle
              title={Translator.trans('branding.subtitle.picture')}
            />
            <div className="row mb-4">
              <div className="col-12 col-lg-4 mb-2">
                <div className="text-center">
                  <h2>{Translator.trans('branding.title.logo')}</h2>
                  {pictureLogo?.original ? (
                    <DeleteBadge onClick={() => deletePicture('deleteLogo')}>
                      <img
                        src={pictureLogo?.original}
                        className="logoImage"
                        alt="logo"
                      />
                    </DeleteBadge>
                  ) : (
                    <DragAndDrop
                      name={'pictureLogoName'}
                      labelText={Translator.trans('appModule.drag.and.drop')}
                      onChange={onSelectFile}
                    />
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-4 mb-2">
                <div className="text-center">
                  <h2>{Translator.trans('branding.title.background')}</h2>
                  {pictureBackground?.original ? (
                    <DeleteBadge
                      onClick={() => deletePicture('deleteBackground')}
                    >
                      <img
                        src={pictureBackground?.original}
                        className="logoImage"
                        alt="logo"
                      />
                    </DeleteBadge>
                  ) : (
                    <DragAndDrop
                      name={'pictureBackgroundName'}
                      labelText={Translator.trans('appModule.drag.and.drop')}
                      onChange={onSelectFile}
                    />
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-4 mb-2">
                <div className="text-center">
                  <h2>{Translator.trans('branding.title.partners')}</h2>
                  {picturePartners?.original ? (
                    <DeleteBadge
                      onClick={() => deletePicture('deletePartners')}
                    >
                      <img
                        src={picturePartners?.original}
                        className="logoImage"
                        alt="logo"
                      />
                    </DeleteBadge>
                  ) : (
                    <DragAndDrop
                      name={'picturePartnersName'}
                      labelText={Translator.trans('appModule.drag.and.drop')}
                      onChange={onSelectFile}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end mt-3">
              <MainButton
                loading={loadingSend}
                onClick={handleSubmit}
                buttonType={'contain'}
              >
                {Translator.trans('appModule.save')}
              </MainButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Config;
