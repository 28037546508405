import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ActionButton } from '@emisys/audience-sdk-ui-react';
import DeleteModal from '../DeleteModal/DeleteModal';
import PlanCategoryModel from '../../Models/PlanCategoryModel';
import { del, getUrl } from '../../Redux/services';
import { NotificationManager } from '../Notification/NotificationManager';
import Translator from '../../services/translator';
import { getPlanCategoriesRequest } from '../../Redux/actions';
import { currentProject } from '../../Global/currentProject';
import PlanCategoryModal from '../../app/PlanCategories/PlanCategoryModal/PlanCategoryModal';

const ButtonsPlanCategory = (props: { plan: PlanCategoryModel }) => {
  const dispatch = useDispatch();
  const [editCategoryModal, setEditCategoryModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const getPlanCategories = useCallback(() => {
    dispatch(
      getPlanCategoriesRequest({
        'project.id': currentProject.id,
      })
    );
  }, [dispatch]);

  const handleRequestDelete = () => {
    setLoading(true);
    del(getUrl('content/v3/map-item-categories/' + props.plan.id, null, ''))
      .then(() => {
        setShowModalDelete(false);
        getPlanCategories();
      })
      .catch(() => {
        NotificationManager.error(Translator.trans('plan.del.category.error'));
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className={'action-container-buttons'}>
      <ActionButton onClick={() => setEditCategoryModal(true)} icon={'edit'} />

      <ActionButton onClick={() => setShowModalDelete(true)} icon={'delete'} />

      {editCategoryModal && (
        <PlanCategoryModal
          currentPlan={props.plan}
          isOpen={editCategoryModal}
          onCancel={() => setEditCategoryModal(false)}
          onConfirm={() => getPlanCategories()}
        />
      )}

      <DeleteModal
        isOpen={showModalDelete}
        onConfirm={handleRequestDelete}
        onCancel={() => {
          setShowModalDelete(false);
        }}
        isLoading={isLoading}
      />
    </div>
  );
};

export default ButtonsPlanCategory;
