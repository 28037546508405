import React from 'react';
import MapPointerModel from '../../../Models/MapPointerModel';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PlanCategoryModel from '../../../Models/PlanCategoryModel';
import PinIcon from './PinIcon';
import '../Plan.css';

interface DraggableItemProps {
  currentCategory: PlanCategoryModel | undefined;
  item: MapPointerModel;
  isSelected: boolean;
  onSelect: (item: MapPointerModel | null) => void;
  onDelete: (id: number) => void;
}

const DraggableItem = (props: DraggableItemProps) => {
  const [dragOver, setDragOver] = React.useState(false);

  const handleDragStart = (e: React.DragEvent<HTMLLIElement>) => {
    const item = props.item;
    setDragOver(true);
    if (item.id === 0) {
      if (item.store) {
        e.dataTransfer.setData('storeId', item.store);
      }
      if (item.place) {
        e.dataTransfer.setData('placeId', item.place);
      }
    } else {
      e.dataTransfer.setData('marker-id', item.id.toString());
    }
  };

  return (
    <li
      draggable
      onDragStart={handleDragStart}
      onDragEnd={() => setDragOver(false)}
      className={`draggable-item ${props.isSelected ? 'select' : ''}`}
      onClick={() => props.onSelect(props.item)}
      style={{ opacity: dragOver ? 0.4 : 1 }}
    >
      <div className={'draggable-item-info'}>
        {props.item.id !== 0 && (
          <PinIcon category={props.currentCategory} size={'small'} />
        )}
        <span>{props.item.displayName}</span>
      </div>

      {props.item.id !== 0 && (
        <DeleteOutlineIcon
          className={'del-draggable-item'}
          onClick={() => props.onDelete(props.item.id)}
        />
      )}
    </li>
  );
};

export default DraggableItem;
