import { planConstants } from '../constants';
import PlanCategoryModel from '../../Models/PlanCategoryModel';

const initialState: any = {
  planCategories: null,
};

let planCategoriesList: PlanCategoryModel[] = [];
const plan = (state = initialState, action: any) => {
  switch (action.type) {
    case planConstants.GET_PLAN_CATEGORIES_REQUEST: {
      return {
        ...state,
        planCategories: {
          categories: planCategoriesList,
          error: false,
          loading: true,
        },
      };
    }

    case planConstants.GET_PLAN_CATEGORIES_SUCCESS: {
      planCategoriesList = action.response['hydra:member'].map(
        (category: any) => new PlanCategoryModel(category)
      );
      return {
        ...state,
        planCategories: {
          categories: planCategoriesList,
          error: false,
          loading: false,
        },
      };
    }

    case planConstants.GET_PLAN_CATEGORIES_FAILURE: {
      return {
        ...state,
        planCategories: { categories: null, error: true, loading: false },
      };
    }

    default:
      return state;
  }
};

export default plan;
