import React from 'react';
import { Modal } from '@emisys/audience-sdk-ui-react';
import PlanCategoryModel from '../../../Models/PlanCategoryModel';
import PinIcon from '../components/PinIcon';
import './SelectCategoryModal.css';
import { getCurrentTranslation } from '../../../services/GetCurrentTranslation';
import { currentProject } from '../../../Global/currentProject';
import AddIcon from '@mui/icons-material/Add';
import Translator from '../../../services/translator';

interface SelectCategoryModalProps {
  categories: PlanCategoryModel[];
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: (cat: PlanCategoryModel) => void;
  openCreateCategory: () => void;
}

const SelectCategoryModal = (props: SelectCategoryModalProps) => (
  <Modal
    title={Translator.trans('plan.category.link.product')}
    width="xs"
    open={props.isOpen}
    close={props.onCancel}
  >
    <div className={'select-categories-container'}>
      {props.categories.map((item: PlanCategoryModel) => (
        <div key={item.id} onClick={() => props.onConfirm(item)}>
          <PinIcon category={item} size={'large'} />
          <span>
            {
              getCurrentTranslation(
                item.translations,
                currentProject.language.locale
              ).name
            }
          </span>
        </div>
      ))}
      <div onClick={props.openCreateCategory}>
        <div className={'marker-icon-color large add'}>
          <AddIcon />
        </div>
        <span>{Translator.trans('plan.category.new')}</span>
      </div>
    </div>
  </Modal>
);

export default SelectCategoryModal;
