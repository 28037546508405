import { combineReducers } from 'redux';
import user from './user.reducer';
import sessions from './sessions.reducer';
import programs from './programs.reducer';
import places from './places.reducer';
import branding from './branding.reducer';
import performers from './performers.reducer';
import uploads from './uploads.reducer';
import contentArticles from './content-articles.reducer';
import contentNews from './content-news.reducer';
import sessionTracks from './session-tracks.reducer';
import project from './project.reducer';
import notifications from './notifications.reducer';
import appMobile from './app-mobile.reducer';
import addresses from './addresses.reducer';
import plan from './plan.reducer';
import { authConstants } from '../constants';

export const appReducer = combineReducers({
  addresses: addresses,
  appMobile: appMobile,
  branding: branding,
  contentArticles: contentArticles,
  contentNews: contentNews,
  notifications: notifications,
  performers: performers,
  places: places,
  plan: plan,
  programs: programs,
  project: project,
  sessions: sessions,
  sessionTracks: sessionTracks,
  uploads: uploads,
  user: user,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === authConstants.USER_LOGOUT) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
