import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Translator from '../../services/translator';
import { CreateButton, HeaderBanner } from '@emisys/audience-sdk-ui-react';
import CategoryIcon from '@mui/icons-material/Category';
import PlanCategoriesTable from './PlanCategoriesTable/PlanCategoriesTable';
import PlanCategoryModal from './PlanCategoryModal/PlanCategoryModal';
import { getPlanCategoriesRequest } from '../../Redux/actions';
import { storeType } from '../../index';
import { checkReduxResponse } from '../../services/checkReduxResponse';
import PlanCategoryModel from '../../Models/PlanCategoryModel';
import { currentProject } from '../../Global/currentProject';

const PlanCategories = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<PlanCategoryModel[]>([]);
  const [addCategoryModal, setAddCategoryModal] = useState(false);

  const getPlanCategories = useCallback(() => {
    dispatch(
      getPlanCategoriesRequest({
        'project.id': currentProject.id,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    getPlanCategories();
  }, [getPlanCategories]);

  const planCategories = useSelector(
    (state: storeType) => state.plan.planCategories
  );

  useEffect(() => {
    if (planCategories) {
      setLoading(planCategories.loading);
      if (checkReduxResponse(planCategories, 'categories')) {
        setRows(planCategories.categories);
      }
    }
  }, [planCategories]);

  return (
    <>
      <div className="app-wrapper div-wrapper-title">
        <div className="headerAlign">
          <HeaderBanner
            title={Translator.trans('plan.category.title')}
            icon={<CategoryIcon />}
          />
          <CreateButton onClick={() => setAddCategoryModal(true)}>
            {Translator.trans('plan.category.create')}
          </CreateButton>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="jr-card">
              <PlanCategoriesTable loading={loading} rows={rows} />
            </div>
          </div>
        </div>
      </div>

      {addCategoryModal && (
        <PlanCategoryModal
          isOpen={addCategoryModal}
          onCancel={() => setAddCategoryModal(false)}
          onConfirm={() => getPlanCategories()}
        />
      )}
    </>
  );
};

export default PlanCategories;
