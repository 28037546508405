export type PlaceTranslation = {
  id: number;
  locale: string;
  name: string;
};

export default class PlaceModel {
  iri: string;
  id: number;
  position: number;
  translations: {
    [lang: string]: PlaceTranslation;
  };
  name: number; // @deprecated

  constructor(data: any) {
    this.iri = data['@id'];
    this.id = data.id;
    this.position = data.position;
    this.name = data.name;
    this.translations = data.translations;
  }
}
