import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Translator from '../../../services/translator';
import {
  postUploadPictureRequest,
  postContentNewsRequest,
  postContentNewsTranslationRequest,
} from '../../../Redux/actions';
import { validURL } from '../../../services/formatText';
import { NotificationManager } from '../../../components/Notification/NotificationManager';
import { HeaderBanner } from '@emisys/audience-sdk-ui-react';
import { currentProject } from '../../../Global/currentProject';
import { pagesConstant } from '../../../Constants/Constant';
import AccordionTranslation from '../../../components/Accordion/AccordionTranslation';
import { MenuBook } from '@mui/icons-material';
import { checkReduxResponse } from '../../../services/checkReduxResponse';
import NewsTranslations from '../NewsContent/NewsTranslations';
import NewsContent from '../NewsContent/NewsContent';
import NewsModel from '../../../Models/NewsModel';
import ButtonsSaveModal from '../../../components/Buttons/ButtonsSaveModal';
import { storeType } from '../../../index';

const NewsCreation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadingSend, setLoadingSend] = useState(false);

  const newsImage: any = useRef(null);
  const translationContent = useRef<any>([]);
  const news = useRef<NewsModel>(new NewsModel({}));

  const translationContentNews = useSelector(
    (state: storeType) => state.contentNews.translationContentNews
  );

  const newContentNews = useSelector(
    (state: storeType) => state.contentNews.newContentNews
  );

  const newUploadPicture = useSelector(
    (state: storeType) => state.uploads.newUploadPicture
  );

  useEffect(() => {
    if (currentProject.allowedLanguages) {
      currentProject.allowedLanguages.forEach((language) => {
        translationContent.current.push({
          locale: language,
          title: '',
          teaser: '',
          content: '',
          externalLinkLabel: '',
          externalLinkUrl: '',
        });
      });
    }
  }, []);

  useEffect(() => {
    if (newUploadPicture) {
      if (checkReduxResponse(newUploadPicture, 'uploadPicture')) {
        news.current.picture = newUploadPicture.uploadPicture.id;
        dispatch(postContentNewsRequest(currentProject.id, news.current));
      }
    }
  }, [dispatch, newUploadPicture]);

  useEffect(() => {
    if (newContentNews) {
      setLoadingSend(newContentNews.loading);
      if (checkReduxResponse(newContentNews, 'contentNews')) {
        news.current = newContentNews.contentNews;

        dispatch(
          postContentNewsTranslationRequest(news.current.id, {
            translations: translationContent.current,
          })
        );
      }
    }
  }, [dispatch, newContentNews]);

  useEffect(() => {
    if (translationContentNews) {
      setLoadingSend(translationContentNews.loading);
      if (checkReduxResponse(translationContentNews, 'contentNews')) {
        NotificationManager.success(
          Translator.trans('news.message.success.added')
        );
        navigate(pagesConstant.news.home);
      }
    }
  }, [dispatch, navigate, translationContentNews]);

  const handleChangeTranslation = (name: string, data: any, locale: string) => {
    let objIndex = translationContent.current.findIndex(
      (item: any) => item.locale === locale
    );
    translationContent.current[objIndex][name] = data;
  };

  const handleChangeNews = (name: string, value: any) => {
    if (name === 'publishDate') {
      news.current.publishDate = value;
    }
  };

  const handleChangePicture = (picture: string | null) =>
    (newsImage.current = picture);

  const handleSubmit = () => {
    const currentNews = translationContent.current.find(
      (element: any) => element.locale === currentProject.language.locale
    );

    if (currentNews?.title) {
      if (
        validURL(news.current.externalLinkUrl) ||
        !news.current.externalLinkUrl
      ) {
        if (newsImage.current) {
          dispatch(
            postUploadPictureRequest({
              type: 'PictureNews',
              project_id: currentProject.id,
              data: newsImage.current,
            })
          );
        } else {
          if (!news.current.externalLinkLabel) {
            news.current.externalLinkLabel = news.current.externalLinkUrl;
          }
          dispatch(postContentNewsRequest(currentProject.id, news.current));
        }
      } else {
        NotificationManager.warning(Translator.trans('message.url.fault'));
      }
    } else {
      NotificationManager.warning(Translator.trans('message.title.missing'));
    }
  };

  return (
    <div className="app-wrapper div-wrapper-title">
      <div className="headerAlign">
        <HeaderBanner
          title={Translator.trans('news.add.news.title')}
          icon={<MenuBook />}
        />
      </div>

      <div className="row">
        <div className="col-12">
          <div className="jr-card">
            {currentProject.allowedLanguages.map((language) => (
              <AccordionTranslation key={language} language={language}>
                <NewsTranslations
                  handleChangeTranslation={handleChangeTranslation}
                  language={language}
                />
              </AccordionTranslation>
            ))}

            <NewsContent
              handleChangeNews={handleChangeNews}
              handleChangePicture={handleChangePicture}
            />

            <ButtonsSaveModal
              onClose={() => navigate(pagesConstant.news.home)}
              onSubmit={handleSubmit}
              isLoading={loadingSend}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsCreation;
