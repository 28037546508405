import React from 'react';
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedFiltering,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  Table,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import ColumnCell from '../../../components/Table/Columns/ColumnCell';
import { Loading } from '@emisys/audience-sdk-ui-react';
import NoData from '../../../components/Table/NoData/NoData';
import TableContainer from '../../../components/Table/TableContainer/TableContainer';
import SortLabel from '../../../components/Table/Sorting/SortLabel';
import { filteringColumnExtensions } from '../../../components/Table/Filters/FilterExtensions';
import { sortingColumnExtensions } from '../../../components/Table/Sorting/SortingExtensions';
import {
  hideSortingButtons,
  rowPerTablePage,
} from '../../../components/Table/Constants/Constant';
import PagingTable from '../../../components/Table/Paging/PagingTable';
import {
  columnsMapCategories,
  tableColumnExtensionsMapCategories,
} from '../../../components/Table/Columns/ShowColumns';
import PlanCategoryModel from '../../../Models/PlanCategoryModel';
import PlanCategoryCell from '../../../components/Table/Cells/Plan/PlanCategoryCell';

const PlanCategoriesTable = (props: {
  loading: boolean;
  rows: PlanCategoryModel[];
}) => {
  return (
    <div>
      <Grid rows={props.rows} columns={columnsMapCategories}>
        <PagingState />
        <SortingState columnExtensions={hideSortingButtons} />
        <IntegratedSorting columnExtensions={sortingColumnExtensions} />
        <IntegratedFiltering columnExtensions={filteringColumnExtensions} />
        <IntegratedPaging />
        <Table
          columnExtensions={tableColumnExtensionsMapCategories}
          cellComponent={PlanCategoryCell}
          tableComponent={TableContainer}
          noDataCellComponent={NoData}
        />

        <TableHeaderRow
          showSortingControls
          sortLabelComponent={SortLabel}
          cellComponent={ColumnCell}
        />
        <PagingPanel
          pageSizes={rowPerTablePage}
          containerComponent={PagingTable}
        />
      </Grid>
      {props.loading && <Loading />}
    </div>
  );
};

export default PlanCategoriesTable;
