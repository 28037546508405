import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { checkReduxResponse } from '../../../services/checkReduxResponse';
import {
  currentProject,
  setCurrentProject,
} from '../../../Global/currentProject';
import {
  clearProjectRequest,
  getBrandingRequest,
  getProjectAddressRequest,
  putBrandingRequest,
  putProjectAddressRequest,
  putProjectDetailsRequest,
} from '../../../Redux/actions';
import Translator from '../../../services/translator';
import { getNameLanguage } from '../../../services/GetNameLanguage';
import {
  HeaderBanner,
  Loading,
  MainButton,
  UnderlineTitle,
  MainTextField,
} from '@emisys/audience-sdk-ui-react';
import { Storefront } from '@mui/icons-material';
import DatePickerComponent from '../../../components/DatePicker/DatePicker';
import Select from 'react-select';
import FooterSave from '../../../components/FooterSave/FooterSave';
import { NotificationManager } from '../../../components/Notification/NotificationManager';
import CountrySelect from '../../../components/CountrySelect/CountrySelect';
import ProjectModel from '../../../Models/ProjectModel';
import AddressModel from '../../../Models/AddressModel';
import {
  eventTypeList,
  getEventType,
  getLanguage,
  languagesList,
} from '../../../Constants/Constant';
import AddressComponent from '../../../components/AddressComponent/AddressComponent';
import { storeType } from '../../../index';

const allTimezones = moment.tz.names();

type languageType = {
  value: string | number;
  label: string;
  isFixed?: boolean;
};

const CurrentEvent = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [audienceDateStart, setAudienceDateStart] = useState<Date>(new Date());
  const [audienceDateStop, setAudienceDateStop] = useState<Date>(new Date());
  const [timezone, setTimezone] = useState<any>('');
  const [defaultCountry, setDefaultCountry] = useState('');
  const [addressProject, setAddressProject] = useState<AddressModel | null>(
    null
  );
  const [allowedLanguages, setAllowedLanguages] = useState<languageType[]>([]);
  const [defaultLanguage, setDefaultLanguage] = useState<any>('');
  const [eventType, setEventType] = useState<any>('');
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);
  const [addressErrors, setAddressErrors] = useState(null);
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [facebookUrl, setFacebookUrl] = useState('');
  const [twitterUrl, setTwitterUrl] = useState('');
  const [instagramUrl, setInstagramUrl] = useState('');

  const projectDetails = useSelector(
    (state: storeType) => state.project.projectDetails
  );
  const updatedProjectDetails = useSelector(
    (state: storeType) => state.project.updatedProjectDetails
  );
  const projectAddress = useSelector(
    (state: storeType) => state.addresses.projectAddress
  );
  const brandingList = useSelector(
    (state: storeType) => state.branding.brandingList
  );

  const initializeProjectDetails = useCallback((project: ProjectModel) => {
    setName(project.name);
    setEventType(getEventType(project.type));
    setAudienceDateStart(project.audienceDateStartUTC);
    setAudienceDateStop(project.audienceDateStopUTC);
    setTimezone({
      value: project.timezone,
      label: project.timezone,
    });
    setDefaultCountry(project.defaultCountry);
    setDefaultLanguage(getLanguage(project.defaultLanguage));
    formatAllowedLanguages(project.allowedLanguages, project.defaultLanguage);
  }, []);

  useEffect(() => {
    if (brandingList) {
      if (checkReduxResponse(brandingList, 'branding')) {
        setWebsiteUrl(brandingList.branding.websiteUrl);
        setFacebookUrl(brandingList.branding.facebookUrl);
        setTwitterUrl(brandingList.branding.twitterUrl);
        setInstagramUrl(brandingList.branding.instagramUrl);
      }
    } else {
      dispatch(getBrandingRequest(currentProject.id));
    }
  }, [brandingList, dispatch]);

  useEffect(() => {
    if (projectDetails) {
      setLoadingPage(projectDetails.loading);
      if (checkReduxResponse(projectDetails, 'detail')) {
        initializeProjectDetails(projectDetails.detail);
      }
    }
  }, [initializeProjectDetails, projectDetails]);

  useEffect(() => {
    if (updatedProjectDetails) {
      setLoadingSend(updatedProjectDetails.loading);
      if (checkReduxResponse(updatedProjectDetails, 'projectDetails')) {
        setCurrentProject(updatedProjectDetails.projectDetails);
        NotificationManager.success(
          Translator.trans('notification.success.updated')
        );
        initializeProjectDetails(updatedProjectDetails.projectDetails);
      }

      if (updatedProjectDetails.error) {
        NotificationManager.error(
          Translator.trans('notification.error.updated')
        );
      }

      dispatch(clearProjectRequest());
    }
  }, [dispatch, initializeProjectDetails, updatedProjectDetails]);

  useEffect(() => {
    if (projectAddress) {
      setLoadingPage(projectAddress.loading);
      if (checkReduxResponse(projectAddress, 'address')) {
        setAddressProject(projectAddress.address);
      }

      if (projectAddress.error) {
        setAddressErrors(projectAddress.error.formErrors);
      }
    } else {
      dispatch(getProjectAddressRequest(currentProject.id));
    }
  }, [dispatch, projectAddress]);

  function getFormattedStateForApi() {
    const payloadProject: any = {
      name: name,
      type: eventType.value,
      audienceDateStart: audienceDateStart,
      audienceDateStop: audienceDateStop,
      timezone: timezone.value,
      defaultLanguage: defaultLanguage.value,
      defaultCountry: defaultCountry,
      allowedLanguages: allowedLanguages.map((data: any) => data.value),
    };

    return payloadProject;
  }

  function formatAllowedLanguages(
    allowedLanguages: string[],
    defaultLanguage: string
  ) {
    let formattedLanguages: languageType[] = [];

    allowedLanguages.map((allowLanguage: string) => {
      if (allowLanguage === defaultLanguage) {
        formattedLanguages.unshift({
          value: allowLanguage,
          label: getNameLanguage(currentProject.language.locale, allowLanguage),
          isFixed: true,
        });
      } else {
        formattedLanguages.push({
          value: allowLanguage,
          label: getNameLanguage(currentProject.language.locale, allowLanguage),
          isFixed: false,
        });
      }
    });

    setAllowedLanguages(formattedLanguages);
  }

  function handleSubmit() {
    dispatch(
      putProjectDetailsRequest(currentProject.id, getFormattedStateForApi())
    );

    dispatch(putProjectAddressRequest(currentProject.id, addressProject));

    dispatch(
      putBrandingRequest(currentProject.id, {
        websiteUrl: websiteUrl,
        facebookUrl: facebookUrl,
        twitterUrl: twitterUrl,
        instagramUrl: instagramUrl,
      })
    );

    setLoadingSend(true);
    setAddressErrors(null);
  }

  const styles: any = {
    multiValue: (base: any, state: any) => {
      return state.data.isFixed
        ? { ...base, backgroundColor: '#e6007e' }
        : base;
    },
    multiValueLabel: (base: any, state: any) => {
      return state.data.isFixed
        ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
        : base;
    },
    multiValueRemove: (base: any, state: any) => {
      return state.data.isFixed ? { ...base, display: 'none' } : base;
    },
  };

  const orderLanguages = (values: any) => {
    return values
      .filter((v: languageType) => v.isFixed)
      .concat(values.filter((v: languageType) => !v.isFixed));
  };

  const onChangeAllowedLanguages = (newValue: any, actionMeta?: any) => {
    switch (actionMeta.action) {
      case 'remove-value':
      case 'pop-value':
        if (actionMeta.removedValue.isFixed) {
          return;
        }
        break;
      case 'clear':
        newValue = allowedLanguages.filter((v: languageType) => v.isFixed);
        break;
    }

    setAllowedLanguages(orderLanguages(newValue));
  };

  const onChangeDefaultLanguage = (language: any) => {
    const newAllowedLanguages = allowedLanguages.map((language) =>
      language.value.toString()
    );

    if (
      !newAllowedLanguages.some(
        (allowedLanguage) => allowedLanguage === language.value
      )
    ) {
      newAllowedLanguages.push(language.value);
    }

    formatAllowedLanguages(newAllowedLanguages, language.value);
    setDefaultLanguage(language);
  };

  return (
    <div className="app-wrapper mb-5">
      <div className="headerAlign">
        <HeaderBanner
          title={Translator.trans('sidenav.title.event')}
          icon={<Storefront />}
        />
      </div>
      <div className="row">
        <div className="col-12">
          <div className="jr-card current-event-container">
            <div className="row mb-4 position-relative">
              <div className="col-lg-6 col-sm-12">
                <MainTextField
                  className="event-input-name"
                  required
                  labelText={Translator.trans('project.label.name')}
                  value={name}
                  onChange={(e: any) => setName(e.target.value)}
                />
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="mb-1 required">
                  {Translator.trans('project.eventType.title')}
                </div>
                <Select
                  className="w-100"
                  value={eventType}
                  onChange={setEventType}
                  options={eventTypeList}
                />
              </div>
            </div>
            <div className="row mb-4 position-relative date-picker-event">
              <div className="col-lg-6 col-sm-12">
                <DatePickerComponent
                  labelText={Translator.trans('appModule.start.date')}
                  required
                  placeholderText={Translator.trans('appModule.start.date')}
                  selected={audienceDateStart}
                  onChange={(dateStart) => {
                    if (dateStart) {
                      setAudienceDateStart(dateStart);
                    }
                  }}
                  showTimeSelect
                />
              </div>
              <div className="col-lg-6 col-sm-12">
                <DatePickerComponent
                  labelText={Translator.trans('appModule.end.date')}
                  required
                  placeholderText={Translator.trans('appModule.end.date')}
                  selected={audienceDateStop}
                  onChange={(dateStop) => {
                    if (dateStop) {
                      setAudienceDateStop(dateStop);
                    }
                  }}
                  showTimeSelect
                />
              </div>
            </div>

            <UnderlineTitle title={Translator.trans('appModule.languages')} />
            <div className="row mb-4 position-relative">
              <div className="col-lg-6 col-sm-12">
                <div className="mb-1 required">
                  {Translator.trans('project.label.defaultLanguage')}
                </div>
                <Select
                  className="w-100"
                  value={defaultLanguage}
                  onChange={onChangeDefaultLanguage}
                  options={languagesList}
                />
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="mb-1 required">
                  {Translator.trans('appModule.languages')}
                </div>
                {allowedLanguages && (
                  <Select
                    isMulti
                    styles={styles}
                    value={allowedLanguages}
                    onChange={onChangeAllowedLanguages}
                    isClearable={allowedLanguages.some(
                      (v: languageType) => !v.isFixed
                    )}
                    options={languagesList}
                    className="w-100"
                  />
                )}
              </div>
              <div className="col-lg-6 col-sm-12 mt-3">
                <div className="mb-1 required">
                  {Translator.trans('project.label.timezone')}
                </div>
                <Select
                  className="w-100"
                  value={timezone}
                  onChange={setTimezone}
                  options={
                    allTimezones &&
                    allTimezones.map((data: string) => ({
                      value: data,
                      label: data,
                    }))
                  }
                />
              </div>
              <div className="col-lg-6 col-sm-12 mt-3">
                <CountrySelect
                  required
                  value={defaultCountry}
                  onChange={setDefaultCountry}
                />
              </div>
            </div>
            <UnderlineTitle title={Translator.trans('project.address.event')} />
            <div className="mb-4">
              <AddressComponent
                address={addressProject}
                handleChange={setAddressProject}
                errors={addressErrors}
              />
            </div>

            <UnderlineTitle
              title={Translator.trans('branding.subtitle.links')}
            />
            <div className="row mb-4">
              <div className="col-lg-6 col-sm-12">
                <div className="mb-2">
                  <MainTextField
                    labelText={Translator.trans('branding.label.url.site')}
                    value={websiteUrl ? websiteUrl : ''}
                    onChange={(event) => setWebsiteUrl(event.target.value)}
                  />
                </div>
                <div className="mb-2">
                  <MainTextField
                    labelText={Translator.trans('branding.label.url.facebook')}
                    placeholder="www.facebook.com"
                    value={facebookUrl}
                    onChange={(event) => setFacebookUrl(event.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="mb-2">
                  <MainTextField
                    labelText={Translator.trans('branding.label.url.twitter')}
                    placeholder="www.twitter.com"
                    value={twitterUrl ? twitterUrl : ''}
                    onChange={(event) => setTwitterUrl(event.target.value)}
                  />
                </div>
                <div className="mb-2">
                  <MainTextField
                    labelText={Translator.trans('branding.label.url.instagram')}
                    placeholder="www.instagram.com"
                    value={instagramUrl ? instagramUrl : ''}
                    onChange={(event) => setInstagramUrl(event.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          {loadingPage && <Loading />}
        </div>

        <FooterSave>
          <MainButton
            className={'event-update-btn'}
            onClick={handleSubmit}
            loading={loadingSend}
            buttonType={'contain'}
            disabled={name === ''}
          >
            {Translator.trans('appModule.save')}
          </MainButton>
        </FooterSave>
      </div>
    </div>
  );
};

export default CurrentEvent;
