import React from 'react';
import { PlanCategoryType } from '../../../Models/PlanCategoryModel';
import AccessibleIcon from '@mui/icons-material/Accessible';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import WcIcon from '@mui/icons-material/Wc';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import RestaurantMenuOutlinedIcon from '@mui/icons-material/RestaurantMenuOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import StadiumOutlinedIcon from '@mui/icons-material/StadiumOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import LocalDrinkOutlinedIcon from '@mui/icons-material/LocalDrinkOutlined';
import ParkOutlinedIcon from '@mui/icons-material/ParkOutlined';
import EuroOutlinedIcon from '@mui/icons-material/EuroOutlined';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import StarIcon from '@mui/icons-material/Star';

export const getMarkerIcon = (type?: PlanCategoryType) => {
  switch (type) {
    case 'activities':
      return <CelebrationOutlinedIcon />;
    case 'atm':
      return <EuroOutlinedIcon />;
    case 'camping':
      return <ParkOutlinedIcon />;
    case 'cashdesk':
      return <ShoppingCartOutlinedIcon />;
    case 'checkin':
      return <HowToRegOutlinedIcon />;
    case 'drinks':
      return <LocalDrinkOutlinedIcon />;
    case 'emergency-exits':
      return <LogoutOutlinedIcon />;
    case 'entrance':
      return <ExitToAppOutlinedIcon />;
    case 'first-aid':
      return <AddOutlinedIcon />;
    case 'food':
      return <RestaurantMenuOutlinedIcon />;
    case 'info-point':
      return <InfoOutlinedIcon />;
    case 'parking':
      return <LocalParkingIcon />;
    case 'partner':
      return <HandshakeOutlinedIcon />;
    case 'stage':
      return <StadiumOutlinedIcon />;
    case 'security':
      return <SecurityOutlinedIcon />;
    case 'ticketing':
      return <LocalActivityOutlinedIcon />;
    case 'toilets':
      return <WcIcon />;
    case 'vip':
      return <StarIcon />;
    case 'water-fountains':
      return <WaterDropIcon />;
    case 'wheelchair-access':
      return <AccessibleIcon />;
    case 'other':
      return <WorkspacesOutlinedIcon />;
    default:
      return <QuestionMarkOutlinedIcon />;
  }
};
