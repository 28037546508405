import React from 'react';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import MapPointerModel from '../../../Models/MapPointerModel';
import PlanCategoryModel from '../../../Models/PlanCategoryModel';
import PinIcon from './PinIcon';
import '../Plan.css';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: 'white',
    fontSize: '16px',
  },
}));

interface PinMarkerProps {
  marker: MapPointerModel;
  planCategories: PlanCategoryModel[];
  onRemove: (id: number) => void;
  sizeImg: {
    width: number;
    height: number;
  };
}

const PinMarker = (props: PinMarkerProps) => {
  const [dragOver, setDragOver] = React.useState(false);

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.dataTransfer.setData('marker-id', props.marker.id.toString());
    setDragOver(true);
  };

  const category = props.planCategories?.find(
    (cate) => cate.iri === props.marker.category
  );

  return (
    <HtmlTooltip
      placement="top"
      arrow
      disableFocusListener
      disableTouchListener
      title={
        <div className="marker-tooltip-text">
          <div>{props.marker.displayName}</div>
        </div>
      }
    >
      <div
        draggable
        onClick={(e) => {
          e.stopPropagation();
          props.onRemove(props.marker.id);
        }}
        onDragStart={handleDragStart}
        onDragEnd={() => setDragOver(false)}
        className="pin-marker"
        style={{
          left: `${(props.marker.offset_x / props.sizeImg.width) * 100}%`,
          top: `${(props.marker.offset_y / props.sizeImg.height) * 100}% `,
          transform: 'translate(-50%, -50%)',
          opacity: dragOver ? 0.4 : 1,
        }}
      >
        <PinIcon category={category} />
      </div>
    </HtmlTooltip>
  );
};

export default PinMarker;
