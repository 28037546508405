import React, { useEffect, useState } from 'react';
import { Modal } from '@emisys/audience-sdk-ui-react';
import Select from 'react-select';
import Translator from '../../../services/translator';
import { getUrl, post, update } from '../../../Redux/services';
import { currentProject } from '../../../Global/currentProject';
import { NotificationManager } from '../../../components/Notification/NotificationManager';
import ButtonsSaveModal from '../../../components/Buttons/ButtonsSaveModal';
import { MainTextField } from '@emisys/audience-sdk-ui-react';
import { capitalizeFirstLetter } from '../../../services/capitalizeFirstLetter';
import PlanCategoryModel, {
  PlanCategoryTradType,
  PlanCategoryType,
} from '../../../Models/PlanCategoryModel';
import './PlanCategoryModal.css';
import { ColorPickerBox } from '../../../components/ColorPicker/ColorPicker';
import { getNameLanguage } from '../../../services/GetNameLanguage';

interface PlanCategoryModalProps {
  currentPlan?: PlanCategoryModel;
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: (newCat?: PlanCategoryModel) => void;
}

const planCategories: PlanCategoryType[] = [
  'activities',
  'atm',
  'camping',
  'cashdesk',
  'checkin',
  'drinks',
  'emergency-exits',
  'entrance',
  'first-aid',
  'food',
  'info-point',
  'parking',
  'partner',
  'stage',
  'security',
  'ticketing',
  'toilets',
  'vip',
  'water-fountains',
  'wheelchair-access',
  'other',
];

const PlanCategoryModal = (props: PlanCategoryModalProps) => {
  const [selectedType, setSelectedType] = useState({
    value: planCategories[0],
    label: capitalizeFirstLetter(planCategories[0]),
  });
  const [selectedColor, setSelectedColor] = useState('#e6007e');
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [translations, setTranslations] = useState<PlanCategoryTradType[]>([]);

  useEffect(() => {
    if (currentProject.allowedLanguages && !props.currentPlan) {
      let newTranslations: PlanCategoryTradType[] = [];
      currentProject.allowedLanguages.forEach((element) => {
        newTranslations.push({
          locale: element,
          name: '',
        });
      });

      setTranslations(newTranslations);
    }
  }, [props.currentPlan]);

  useEffect(() => {
    if (props.currentPlan) {
      setSelectedType({
        value: props.currentPlan.type,
        label: capitalizeFirstLetter(props.currentPlan.type),
      });
      setSelectedColor(props.currentPlan.color);

      let newTranslations: PlanCategoryTradType[] = [];
      Object.values(props.currentPlan.translations).forEach((element) => {
        newTranslations.push({
          locale: element.locale,
          name: element.name,
        });
      });

      setTranslations(newTranslations);
    }
  }, [props.currentPlan]);

  const addMapCategory = () => {
    const defaultName = translations.find(
      (trad) => trad.locale === currentProject.defaultLanguage
    );
    if (defaultName?.name !== '') {
      setLoading(true);
      post(getUrl('content/v3/map-item-categories', null, ''), {
        color: selectedColor,
        type: selectedType.value,
        project: '/api/event/v3/projects/' + currentProject.id.toString(),
        translations: translations,
      })
        .then((response) => {
          const newCat = new PlanCategoryModel(response);
          props.onCancel();
          props.onConfirm(newCat);
        })
        .catch(() => {
          NotificationManager.error(Translator.trans('plan.post.marker.error'));
        })
        .finally(() => setLoading(false));
    } else {
      NotificationManager.error(Translator.trans('plan.category.name.error'));
    }
  };

  const editMapCategory = () => {
    const defaultName = translations.find(
      (trad) => trad.locale === currentProject.defaultLanguage
    );
    if (defaultName?.name !== '' && props.currentPlan) {
      setLoading(true);
      update(
        getUrl(
          'content/v3/map-item-categories/' + props.currentPlan.id,
          null,
          ''
        ),
        {
          id: props.currentPlan.id,
          color: selectedColor,
          type: selectedType.value,
          translations: translations,
        }
      )
        .then(() => {
          props.onCancel();
          props.onConfirm();
        })
        .catch(() => {
          NotificationManager.error(Translator.trans('plan.post.marker.error'));
        })
        .finally(() => setLoading(false));
    } else {
      NotificationManager.error(Translator.trans('plan.category.name.error'));
    }
  };

  const handleChangeTranslation = (value: string, locale: string) => {
    let newTranslations = translations.map((item) => {
      if (item.locale === locale) {
        return { ...item, name: value };
      }
      return item;
    });

    setTranslations(newTranslations);
  };

  return (
    <Modal
      title={
        props.currentPlan
          ? Translator.trans('plan.category.title.edit')
          : Translator.trans('plan.category.title.add')
      }
      width="md"
      open={props.isOpen}
      close={props.onCancel}
    >
      <div className={'marker-modal-body'}>
        <div className="row align-items-center mt-3">
          <div className="col-12 col-md-4">
            <h4>{Translator.trans('plan.category.type')}</h4>
          </div>
          <div className="col-12 col-md-8 d-flex">
            <ColorPickerBox
              show={showColorPicker}
              color={selectedColor}
              changeColor={setSelectedColor}
              onClick={() => setShowColorPicker(!showColorPicker)}
            />
            <Select
              className="plan-category-select"
              value={selectedType}
              onChange={(option: any) => setSelectedType(option)}
              options={planCategories.map((data: PlanCategoryType) => ({
                value: data,
                label: capitalizeFirstLetter(data),
              }))}
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12">
            <h4>
              <b>{Translator.trans('plan.category.name')}</b>
            </h4>
          </div>
        </div>

        {currentProject.allowedLanguages.map((language) => {
          let currentTrad = translations.find(
            (item: any) => item.locale === language
          );

          return (
            <div key={language} className="row align-items-center mt-3">
              <div className="col-12 col-md-4">
                <h4>
                  {getNameLanguage(currentProject.language.locale, language)}
                </h4>
              </div>
              <div className="col-12 col-md-8">
                <MainTextField
                  value={currentTrad?.name}
                  onChange={(event) =>
                    handleChangeTranslation(
                      capitalizeFirstLetter(event.target.value),
                      language
                    )
                  }
                />
              </div>
            </div>
          );
        })}
      </div>

      <ButtonsSaveModal
        onClose={props.onCancel}
        onSubmit={props.currentPlan ? editMapCategory : addMapCategory}
        isLoading={isLoading}
      />
    </Modal>
  );
};

export default PlanCategoryModal;
