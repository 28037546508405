import React, { useState, useEffect } from 'react';
import ImageCanvas from './ImageCanvas';
import DeleteBadge from '../../../components/DeleteBadge/DeleteBadge';
import { getUrl, post, update } from '../../../Redux/services';
import { currentProject } from '../../../Global/currentProject';
import StoreModel from '../../../Models/StoreModel';
import { NotificationManager } from '../../../components/Notification/NotificationManager';
import Translator from '../../../services/translator';
import MapPointerModel from '../../../Models/MapPointerModel';
import { DraggableCategory } from './DraggableCategory';
import PlanCategoryModel from '../../../Models/PlanCategoryModel';
import Slider from '@mui/material/Slider';
import PlanCategoryModal from '../../PlanCategories/PlanCategoryModal/PlanCategoryModal';
import SelectCategoryModal from '../SelectCategoryModal/SelectCategoryModal';
import PlaceModel from '../../../Models/PlaceModel';
import '../Plan.css';

interface DraggableImageProps {
  imageUrl: string;
  deleteImage: () => void;
  stores: StoreModel[];
  places: PlaceModel[];
  onItemSelect: (item: MapPointerModel | null) => void;
  selectedItem: MapPointerModel | null;
  markersItems: MapPointerModel[];
  planCategories: PlanCategoryModel[];
  setMarkersItems: (marker: MapPointerModel[]) => void;
  refreshCategories: () => void;
}

const DraggableImage = (props: DraggableImageProps) => {
  const [opacityImg, setOpacityImg] = useState(100);
  const [createCategoryModal, setCreateCategoryModal] = useState(false);
  const [selectCategoryModal, setSelectCategoryModal] = useState(false);
  const [pendingPayload, setPendingPayload] = useState<any>(null);
  const [sizeImg, setSizeImg] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (props.imageUrl) {
      let myImage = new Image();
      myImage.onload = function () {
        setSizeImg({
          width: myImage.width,
          height: myImage.height,
        });
      };
      myImage.src = props.imageUrl;
    }
  }, [props.imageUrl]);

  const handleImageClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!props.selectedItem) {
      return;
    }

    const rect = e.currentTarget.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * sizeImg.width;
    const y = ((e.clientY - rect.top) / rect.height) * sizeImg.height;

    if (props.selectedItem.id !== 0) {
      updatePosition(props.selectedItem.id.toString(), x, y);
    } else {
      if (props.selectedItem.store) {
        checkForCategory(x, y, props.selectedItem.store, null);
      }
      if (props.selectedItem.place) {
        checkForCategory(x, y, null, props.selectedItem.place);
      }
    }

    props.onItemSelect(null);
  };

  const checkForCategory = (
    x: number,
    y: number,
    store: string | null,
    place: string | null
  ) => {
    setPendingPayload({
      store: store,
      place: place,
      offset_x: Math.round(x),
      offset_y: Math.round(y),
      project: '/api/event/v3/projects/' + currentProject.id.toString(),
    });

    if (props.planCategories.length > 0) {
      setSelectCategoryModal(true);
    } else {
      setCreateCategoryModal(true);
    }
  };

  const addListItemPosition = (category: string | undefined) => {
    if (category && pendingPayload) {
      props.refreshCategories();
      addPosition({ ...pendingPayload, category: category });
    }
  };

  const addCatPosition = (cat: string, x: number, y: number) => {
    addPosition({
      offset_x: Math.round(x),
      offset_y: Math.round(y),
      project: '/api/event/v3/projects/' + currentProject.id.toString(),
      category: cat,
    });
  };

  const addPosition = (payload: any) => {
    const tempMarker = new MapPointerModel(payload);
    const newMarkers = [...props.markersItems, tempMarker];
    props.setMarkersItems(newMarkers);

    post(getUrl('content/v3/map-items.json', null, ''), payload)
      .then((response) => {
        if (pendingPayload) {
          setPendingPayload(null);
          setSelectCategoryModal(false);
          setCreateCategoryModal(false);
        }

        const newMapPointer = new MapPointerModel(response);
        newMapPointer.setDisplayName(
          props.stores,
          props.places,
          props.planCategories
        );

        const markers = newMarkers.map((marker) =>
          marker === tempMarker ? newMapPointer : marker
        );
        props.setMarkersItems(markers);
      })
      .catch(() => {
        props.setMarkersItems(
          props.markersItems.filter((marker) => marker !== tempMarker)
        );

        NotificationManager.error(Translator.trans('plan.post.marker.error'));
      });
  };

  const updatePosition = (id: string, x: number, y: number) => {
    const find = props.markersItems.find(
      (marker) => marker.id.toString() === id
    );
    const newX = Math.round(x);
    const newY = Math.round(y);
    if (find) {
      props.setMarkersItems(
        props.markersItems.map((marker: MapPointerModel) =>
          marker.id.toString() === id
            ? new MapPointerModel({
                ...marker,
                offset_x: newX,
                offset_y: newY,
              })
            : marker
        )
      );

      update(getUrl('content/v3/map-items/' + id, null, ''), {
        ...find,
        offset_x: newX,
        offset_y: newY,
      }).catch(() => {
        props.setMarkersItems(
          props.markersItems.map((marker: MapPointerModel) =>
            marker.id.toString() === id
              ? new MapPointerModel({ ...find })
              : marker
          )
        );
        NotificationManager.error(Translator.trans('plan.put.marker.error'));
      });
    } else {
      NotificationManager.error(Translator.trans('plan.put.marker.error'));
    }
  };

  const removePosition = (id: number) => {
    const find = props.markersItems.find((marker) => marker.id === id);
    if (find) {
      props.setMarkersItems(
        props.markersItems.map((marker: MapPointerModel) =>
          marker.id === id
            ? new MapPointerModel({
                ...marker,
                offset_x: 0,
                offset_y: 0,
              })
            : marker
        )
      );

      update(getUrl('content/v3/map-items/' + id, null, ''), {
        ...find,
        offset_x: 0,
        offset_y: 0,
      }).catch(() => {
        props.setMarkersItems(
          props.markersItems.map((marker: MapPointerModel) =>
            marker.id === id ? new MapPointerModel({ ...find }) : marker
          )
        );
        NotificationManager.error(Translator.trans('plan.put.marker.error'));
      });
    } else {
      NotificationManager.error(Translator.trans('plan.put.marker.error'));
    }
  };

  return (
    <>
      <div className="draggable-image-container">
        <div className="draggable-image-header">
          <div>
            {props.planCategories.length > 0 && (
              <ul>
                {props.planCategories.map((item: PlanCategoryModel) => (
                  <DraggableCategory key={item.id} item={item} />
                ))}
              </ul>
            )}
          </div>
          <div className={'draggable-image-slider'}>
            <Slider
              value={opacityImg}
              onChange={(_, newValue) => {
                setOpacityImg(newValue as number);
              }}
            />
          </div>
        </div>
        <DeleteBadge onClick={props.deleteImage}>
          <ImageCanvas
            imageUrl={props.imageUrl}
            markersItems={props.markersItems}
            onImageClick={handleImageClick}
            onRemovePosition={removePosition}
            onDropListItem={checkForCategory}
            onDropCat={addCatPosition}
            onMovePin={updatePosition}
            sizeImg={sizeImg}
            planCategories={props.planCategories}
            opacity={opacityImg}
          />
        </DeleteBadge>
      </div>

      {selectCategoryModal && (
        <SelectCategoryModal
          isOpen={selectCategoryModal}
          onCancel={() => {
            setPendingPayload(null);
            setSelectCategoryModal(false);
          }}
          categories={props.planCategories}
          onConfirm={(newCat: PlanCategoryModel) =>
            addListItemPosition(newCat.iri)
          }
          openCreateCategory={() => {
            setSelectCategoryModal(false);
            setCreateCategoryModal(true);
          }}
        />
      )}

      {createCategoryModal && (
        <PlanCategoryModal
          isOpen={createCategoryModal}
          onCancel={() => {
            setPendingPayload(null);
            setCreateCategoryModal(false);
          }}
          onConfirm={(newCat?: PlanCategoryModel) =>
            addListItemPosition(newCat?.iri)
          }
        />
      )}
    </>
  );
};

export default DraggableImage;
