import React, { useState } from 'react';
import Translator from '../../../services/translator';
import { currentProject } from '../../../Global/currentProject';
import DatePickerComponent from '../../../components/DatePicker/DatePicker';
import { MainTextField } from '@emisys/audience-sdk-ui-react';
import FormHelperText from '@mui/material/FormHelperText';

const NotificationsCreationModalContent = (props: {
  handleChangeTranslation: (
    data: string,
    value: string,
    locale: string
  ) => void;
  handleChangeDate: (date: Date | null) => void;
  errors: { [key: string]: string } | null;
}) => {
  const [heading, setHeading] = useState('');
  const [content, setContent] = useState('');
  const [dateSendAfter, setDateSendAfter] = useState<Date | null>(null);

  const handleChangeDateSendAfter = (date: Date | null) => {
    setDateSendAfter(date);
    props.handleChangeDate(date);
  };

  const handleChangeHeading = (event: any) => {
    const newValue = event.target.value;
    changeTranslation('heading', newValue);
    setHeading(newValue);
  };

  const handleChangeContent = (event: any) => {
    const newValue = event.target.value;
    changeTranslation('content', newValue);
    setContent(newValue);
  };

  const changeTranslation = (data: string, value: string) => {
    props.handleChangeTranslation(data, value, currentProject.language.locale);
  };

  return (
    <div className="row">
      <div className="col-12 col-md-4 mt-3">
        <h4>{Translator.trans('cms.title')}</h4>
      </div>
      <div className="col-12 col-md-8 mt-4">
        <MainTextField
          name={'heading'}
          value={heading}
          onChange={handleChangeHeading}
          placeholder={Translator.trans('notifications.add.title.placeholder')}
          helperText={props.errors?.heading}
          error={props.errors?.hasOwnProperty('heading')}
        />
      </div>
      <div className="col-12 col-md-4 mt-3">
        <h4>{Translator.trans('notifications.add.title.message')}</h4>
      </div>
      <div className="col-12 col-md-8 mt-4">
        <MainTextField
          name={'content'}
          value={content}
          onChange={handleChangeContent}
          placeholder={Translator.trans(
            'notifications.add.message.placeholder'
          )}
          helperText={props.errors?.content}
          error={props.errors?.hasOwnProperty('content')}
        />
      </div>
      <div className="col-12 col-md-4 mt-3">
        <h4>{Translator.trans('notifications.add.title.date.sent')}</h4>
      </div>
      <div className="col-12 col-md-8 mt-4">
        <DatePickerComponent
          selected={dateSendAfter ? dateSendAfter : null}
          onChange={handleChangeDateSendAfter}
          minDate={new Date()}
          showTimeSelect
          shouldCloseOnSelect
        />
        <FormHelperText error>{props.errors?.dateSendAfter}</FormHelperText>
      </div>
    </div>
  );
};

export default NotificationsCreationModalContent;
