import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { planConstants } from '../constants';
import { getPlanCategoriesFailure, getPlanCategoriesSuccess } from '../actions';
import { getPlanCategoriesApi } from '../api';

export function* fetchGetPlanCategories(arg: any): any {
  try {
    const planCategories = yield call(getPlanCategoriesApi, arg.params);
    yield put(getPlanCategoriesSuccess(planCategories));
  } catch (error) {
    yield put(getPlanCategoriesFailure(error));
  }
}

export function* getPlanCategories() {
  yield takeLatest(
    planConstants.GET_PLAN_CATEGORIES_REQUEST,
    fetchGetPlanCategories
  );
}

export default function* planSaga() {
  yield all([fork(getPlanCategories)]);
}
