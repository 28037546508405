import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { getCurrentTranslation } from '../../../../services/GetCurrentTranslation';
import { currentProject } from '../../../../Global/currentProject';
import { columnNameAction } from '../../Constants/Constant';
import ButtonsPlanCategory from '../../../Buttons/ButtonsPlanCategory';
import PinIcon from '../../../../app/Plan/components/PinIcon';

const PlanCategoryCell = (cell: any) => {
  let content = cell.value;
  const locale = currentProject.language.locale;

  if (cell.column.name === 'name') {
    content = (
      <div className={'color-name-container'}>
        <PinIcon category={cell.row} />
        <span>{getCurrentTranslation(cell.row.translations, locale).name}</span>
      </div>
    );
  }
  if (cell.column.name === columnNameAction) {
    content = <ButtonsPlanCategory plan={cell.row} />;
  }

  return (
    <Table.Cell style={cell.style} {...cell}>
      {content}
    </Table.Cell>
  );
};

export default PlanCategoryCell;
