import { planConstants } from '../constants';

export const getPlanCategoriesRequest = (params: any) => ({
  type: planConstants.GET_PLAN_CATEGORIES_REQUEST,
  params,
});

export const getPlanCategoriesSuccess = (response: any) => ({
  type: planConstants.GET_PLAN_CATEGORIES_SUCCESS,
  response,
});

export const getPlanCategoriesFailure = (error: any) => ({
  type: planConstants.GET_PLAN_CATEGORIES_FAILURE,
  error,
});
