export const getCurrentTranslation = (
  translations: { [locale: string]: any },
  locale: string
) => {
  const translation = translations[locale];

  if (translation) {
    return translation;
  }

  return Object.values(translations)[0];
};
