import React, { useEffect, useState } from 'react';
import Timeline, {
  TimelineMarkers,
  TodayMarker,
  TimelineHeaders,
  DateHeader,
  SidebarHeader,
  ReactCalendarItemRendererProps,
} from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import PlaceModel from '../../../Models/PlaceModel';
import Translator from '../../../services/translator';
import SessionModel from '../../../Models/SessionModel';
import { getCurrentTranslation } from '../../../services/GetCurrentTranslation';
import { currentProject } from '../../../Global/currentProject';

const LineUpHorizontal = (props: {
  chooseDate: Date;
  minView: number;
  maxView: number;
  placesList: PlaceModel[];
  sessionsList: SessionModel[];
  setOpenModalSession: (arg: boolean) => void;
  setIdSession: (arg: string | number) => void;
}) => {
  const locale = currentProject.language.locale;
  const [places, setPlaces] = useState<any[]>([]);
  const [sessions, setSessions] = useState<any[]>([]);

  useEffect(() => {
    if (props.placesList) {
      const newPlaces = props.placesList.map((place: PlaceModel) => {
        const placeName =
          getCurrentTranslation(place.translations, locale).name ?? '';
        return {
          id: place.id,
          title: placeName,
        };
      });

      setPlaces(newPlaces);
    }
  }, [locale, props.placesList]);

  useEffect(() => {
    if (props.sessionsList) {
      const timeToBeConfirmed =
        ' ( ' + Translator.trans('sessions.title.timeToBeConfirmed') + ' )';
      let arraySession: any[] = [];
      props.sessionsList.forEach((element: SessionModel) => {
        if (element.sessionPlaces && element.sessionPlaces?.length !== 0) {
          arraySession.push({
            id: element.id,
            title: element.timeToBeConfirmed
              ? element.title + timeToBeConfirmed
              : element.title,
            group: element.sessionPlaces[0].place.id,
            start_time: element.liveStartDateTimeUTC,
            end_time: element.liveEndDateTimeUTC,
            itemProps: {
              className: 'item',
              style: {
                background: element.timeToBeConfirmed ? '#999999' : '#e6007e',
              },
            },
          });
        }
      });

      setSessions(arraySession);
    }
  }, [props.sessionsList]);

  const itemRenderer = ({
    item,
    itemContext,
    getItemProps,
    getResizeProps,
  }: ReactCalendarItemRendererProps) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
    let color: any = '';

    if (item.itemProps?.style?.background) {
      color = item.itemProps?.style?.background;
    }

    return (
      <div
        {...getItemProps({
          style: {
            backgroundColor: color,
            borderColor: color,
            borderStyle: 'solid',
            borderWidth: 1,
            borderRadius: 4,
            borderLeftWidth: itemContext.selected ? 3 : 1,
            borderRightWidth: itemContext.selected ? 3 : 1,
          },
          onMouseDown: () => {
            props.setOpenModalSession(true);
            props.setIdSession(item.id);
          },
        })}
      >
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

        <div
          style={{
            height: itemContext.dimensions.height,
            overflow: 'hidden',
            paddingLeft: 3,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {itemContext.title}
        </div>

        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
      </div>
    );
  };

  return (
    <Timeline
      groups={places}
      items={sessions}
      canMove={false}
      canResize={false}
      stackItems={true}
      defaultTimeStart={new Date(props.chooseDate.setHours(0, 0, 0, 0))}
      defaultTimeEnd={new Date(props.chooseDate.setHours(23, 59, 59, 999))}
      visibleTimeStart={props.minView}
      visibleTimeEnd={props.maxView}
      minZoom={60 * 60 * 12000} // = 12 hours
      lineHeight={50}
      itemRenderer={itemRenderer}
    >
      <TimelineHeaders style={{ background: '#e6007e' }}>
        <SidebarHeader>
          {({ getRootProps }) => (
            <div style={{ backgroundColor: 'white' }}>
              <div {...getRootProps()} />
            </div>
          )}
        </SidebarHeader>

        <DateHeader
          unit="primaryHeader"
          style={{
            color: 'white',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
          }}
          intervalRenderer={({ getIntervalProps, intervalContext }: any) => (
            <div {...getIntervalProps()} onClick={null}>
              {intervalContext.intervalText}
            </div>
          )}
        />
        <DateHeader
          style={{ background: 'white' }}
          intervalRenderer={({ getIntervalProps, intervalContext }: any) => (
            <div
              style={{
                color: 'black',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRight: '1px solid #bbb',
                ...getIntervalProps().style,
              }}
            >
              {intervalContext.intervalText}
            </div>
          )}
        />
      </TimelineHeaders>
      <TimelineMarkers>
        <TodayMarker date={props.chooseDate}>
          {({ styles }) => (
            <div
              style={{
                ...styles,
                backgroundColor: 'red',
                zIndex: 99,
              }}
            />
          )}
        </TodayMarker>
      </TimelineMarkers>
    </Timeline>
  );
};

export default LineUpHorizontal;
