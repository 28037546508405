import React, { Suspense, useCallback, useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {
  Route,
  Routes,
  useNavigate,
  useLocation,
  Navigate,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isIOS, isMobile } from 'react-device-detect';
import {
  getUserDetailsRequest,
  getUserProjectRequest,
  getUserProjectsListRequest,
  getProjectDetailsRequest,
} from '../Redux/actions';
import { checkReduxResponse } from '../services/checkReduxResponse';

import { ErrorFallback, Loading } from '@emisys/audience-sdk-ui-react';
import Sidebar from '../components/SideNav';
import Header from '../components/Header';
import { ToastContainer } from 'react-toastify';
import { ErrorBoundary } from 'react-error-boundary';

import Error404 from '../components/Error404/Error404';
import { currentProject, setCurrentProject } from '../Global/currentProject';
import { getAuthTokensStorage, getTokenStorage } from '../services/handleToken';
import { getCurrentProject } from '../services/getCurrentProject';
import initTranslations from '../services/initTranslations';
import Translator from '../services/translator';
import { storeType } from '../index';

import Home from './Home';
import Account from './Account';
import LineUp from './Dashboard/LineUp';
import Places from './Places';
import ContentArticles from './ContentArticles';
import { ContentNews } from './News';
import ArticleEdit from './ContentArticles/ArticleEdit/ArticleEdit';
import NewsEdit from './News/NewsEdit/NewsEdit';
import ArticleCreation from './ContentArticles/ArticleCreation/ArticleCreation';
import NewsCreation from './News/NewsCreation/NewsCreation';
import Performers from './Performers';
import PerformerEdit from './Performers/PerformerEdit/PerformerEdit';
import PerformerCreation from './Performers/PerformerCreation/PerformerCreation';
import Sessions from './Sessions';
import SessionCreation from './Sessions/SessionCreate/SessionCreation';
import SessionEdit from './Sessions/SessionEdition/SessionEdit';
import SessionTracks from './SessionTracks';
import Notifications from './Notifications/Notifications';
import Config from './Config/Config';
import SignIn from './SignIn/SignIn';
import { pagesConstant } from '../Constants/Constant';
import CurrentEvent from './Events/Current/CurrentEvent';
import EventsList from './Events/List/EventsList';
import Plan from './Plan/Plan';
import PlanCategories from './PlanCategories';

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [showTicketing, setShowTicketing] = useState(false);
  const [navCollapsed, setNavCollapsed] = useState(false);
  const [currentLocale, setCurrentLocale] = useState('');
  const [setUpMail, setSetUpMail] = useState<string | null>(null);
  const [setUpToken, setSetUpToken] = useState<string | null>(null);

  const userSelfDetails = useSelector(
    (state: storeType) => state.user.userSelfDetails
  );
  const userSelfProjects = useSelector(
    (state: storeType) => state.user.userSelfProjects
  );
  const projectDetails = useSelector(
    (state: storeType) => state.project.projectDetails
  );

  const userProjectByProject = useSelector(
    (state: storeType) => state.user.userProjectByProject
  );

  const onToggleCollapsedNav = () => {
    setNavCollapsed(!navCollapsed);
  };

  const getUserSelfProjects = useCallback(() => {
    dispatch(getUserProjectsListRequest());
  }, [dispatch]);

  const getUserSelfDetails = useCallback(
    (projectId: number) => {
      dispatch(getUserDetailsRequest(projectId));
    },
    [dispatch]
  );

  const getProjectDetails = useCallback(
    (projectId: number) => {
      dispatch(getProjectDetailsRequest(projectId));
    },
    [dispatch]
  );

  const getUserProjectByProject = useCallback(
    (userId: number, projectId: number) => {
      dispatch(getUserProjectRequest(projectId, userId));
    },
    [dispatch]
  );

  useEffect(() => {
    const locationHref = new URL(window.location.href);
    const project_id = locationHref.searchParams.get('p');
    const email = locationHref.searchParams.get('email');
    const token = locationHref.searchParams.get('token');
    setSetUpMail(email);
    setSetUpToken(token);
    if (project_id) {
      localStorage.setItem('project_id', project_id);
    }
  }, []);

  useEffect(() => {
    if (getTokenStorage()) {
      if (userSelfProjects) {
        if (checkReduxResponse(userSelfProjects, 'projects')) {
          if (userSelfProjects.projects.length > 0) {
            const currentProject = getCurrentProject(userSelfProjects.projects);
            if (!userSelfDetails) {
              getUserSelfDetails(currentProject.id);
            }
            if (!projectDetails) {
              getProjectDetails(currentProject.id);
            }
          }
        }
      } else {
        getUserSelfProjects();
      }
    }
  }, [
    userSelfProjects,
    getUserSelfDetails,
    getProjectDetails,
    userSelfDetails,
    projectDetails,
    getUserSelfProjects,
  ]);

  useEffect(() => {
    if (checkReduxResponse(projectDetails, 'detail')) {
      setCurrentProject(projectDetails.detail);
      const localFromStorage = localStorage.getItem('locale');
      if (localFromStorage) {
        initTranslations(localFromStorage);
      } else {
        initTranslations(projectDetails.detail.defaultLanguage);
      }
      setShowTicketing(true);
    }
  }, [dispatch, projectDetails]);

  useEffect(() => {
    if (userSelfDetails && currentProject.id) {
      if (checkReduxResponse(userSelfDetails, 'details')) {
        if (userSelfProjects.projects.length > 0) {
          if (!userProjectByProject) {
            getUserProjectByProject(
              userSelfDetails.details.id,
              currentProject.id
            );
          }
        }
      }
    }
  }, [
    navigate,
    getUserProjectByProject,
    userSelfDetails,
    userSelfProjects?.projects?.length,
    location.pathname,
    userProjectByProject,
  ]);

  // set default height and overflow for iOS mobile Safari 10+ support.
  if (isIOS && isMobile) {
    document.body.classList.add('ios-mobile-view-height');
  } else if (document.body.classList.contains('ios-mobile-view-height')) {
    document.body.classList.remove('ios-mobile-view-height');
  }

  return (
    <div className={'app-main'}>
      <ErrorBoundary
        FallbackComponent={(error) => (
          <ErrorFallback
            error={error.error}
            title={Translator.trans('appModule.message.error')}
            back={Translator.trans('extraPages.goHome')}
          />
        )}
      >
        <Suspense fallback={<Loading />}>
          {getAuthTokensStorage() && (!setUpMail || !setUpToken) ? (
            <div className="app-container isLoggedIn">
              <Sidebar
                onToggleCollapsedNav={onToggleCollapsedNav}
                navCollapsed={navCollapsed}
              />

              {showTicketing ? (
                <div className="app-main-container">
                  <div className="app-header">
                    <Header
                      currentLocale={currentLocale}
                      setCurrentLocale={setCurrentLocale}
                      onToggleCollapsedNav={onToggleCollapsedNav}
                    />
                  </div>

                  <main className="app-main-content-wrapper">
                    <div className="app-main-content">
                      {/* prettier-ignore */}
                      <Routes>
                        <Route path={pagesConstant.home} element={<Home />} />
                        <Route path={pagesConstant.profile} element={<Account />} />
                        <Route path={pagesConstant.lineUp} element={<LineUp />}/>
                        <Route path={pagesConstant.places} element={<Places />} />
                        <Route path={pagesConstant.article.home} element={<ContentArticles />}/>
                        <Route path={pagesConstant.article.edit + ':id'} element={<ArticleEdit />}/>
                        <Route path={pagesConstant.article.add} element={<ArticleCreation />}/>
                        <Route path={pagesConstant.news.home} element={<ContentNews />} />
                        <Route path={pagesConstant.news.edit + ':id'} element={<NewsEdit />}/>
                        <Route path={pagesConstant.news.add} element={<NewsCreation />}/>
                        <Route path={pagesConstant.performers.home} element={<Performers />}/>
                        <Route path={pagesConstant.performers.edit +':id'} element={<PerformerEdit />}/>
                        <Route path={pagesConstant.performers.add} element={<PerformerCreation />}/>
                        <Route path={pagesConstant.sessions.home} element={<Sessions />}/>
                        <Route path={pagesConstant.sessions.edit + ':id'} element={<SessionEdit />}/>
                        <Route path={pagesConstant.sessions.add} element={<SessionCreation />}/>
                        <Route path={pagesConstant.sessiontracks} element={<SessionTracks />}/>
                        <Route path={pagesConstant.plan.home} element={<Plan />}/>
                        <Route path={pagesConstant.plan.categories} element={<PlanCategories />}/>
                        <Route path={pagesConstant.notifications} element={<Notifications />}/>
                        <Route path={pagesConstant.config} element={<Config />} />
                        <Route path={pagesConstant.events.current} element={<CurrentEvent />} />
                        <Route path={pagesConstant.events.list} element={<EventsList />} />
                        <Route path="/*" element={<Navigate to={pagesConstant.home} />} />
                        <Route path='*' element={<Error404 />} />
                      </Routes>
                    </div>
                    <ToastContainer />
                  </main>
                </div>
              ) : (
                <Loading />
              )}
            </div>
          ) : (
            <Routes>
              <Route path={pagesConstant.home} element={<SignIn />} />
              <Route path="/*" element={<Navigate to={pagesConstant.home} />} />
            </Routes>
          )}
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};

export default App;
