import { currentProject } from '../Global/currentProject';
import { getCurrentTranslation } from '../services/GetCurrentTranslation';
import StoreModel from './StoreModel';
import PlanCategoryModel from './PlanCategoryModel';
import PlaceModel from './PlaceModel';

export default class MapPointerModel {
  id: number;
  offset_x: number;
  offset_y: number;
  store: string | null;
  place: string | null;
  category: string | null;
  project: string;
  displayName: string;

  constructor(data: any) {
    this.id = data.id ?? 0;
    this.offset_x = data.offset_x ?? 0;
    this.offset_y = data.offset_y ?? 0;
    this.store = data.store ?? null;
    this.place = data.place ?? null;
    this.category = data.category ?? null;
    this.displayName = data.displayName ?? '';
    this.project = '/api/event/v3/projects/' + currentProject.id.toString();
  }

  setDisplayName(
    stores: StoreModel[],
    places: PlaceModel[],
    categories: PlanCategoryModel[]
  ) {
    const entities = [
      { type: 'store', data: stores, key: this.store },
      { type: 'place', data: places, key: this.place },
      { type: 'category', data: categories, key: this.category },
    ];

    for (const { data, key } of entities) {
      if (key && data) {
        const foundEntity = data.find((entity) => entity.iri === key);
        if (foundEntity) {
          const current =
            getCurrentTranslation(
              foundEntity.translations,
              currentProject.language.locale
            ) ?? foundEntity;
          this.displayName = current.name;
          break;
        }
      }
    }
  }
}
