import React, { useEffect, useState } from 'react';
import { UnderlineTitle, ImageCropper } from '@emisys/audience-sdk-ui-react';
import Translator from '../../../services/translator';
import DatePickerComponent from '../../../components/DatePicker/DatePicker';
import NewsModel from '../../../Models/NewsModel';
import DeleteBadge from '../../../components/DeleteBadge/DeleteBadge';

interface NewsEditContentProps {
  news?: NewsModel;
  handleChangeNews: (name: string, value: string | null | Date) => void;
  handleChangePicture: (picture: string | null) => void;
}

const NewsContent = (props: NewsEditContentProps) => {
  const [publishDate, setPublishDate] = useState<Date | null>(null);
  const [picture, setPicture] = useState<string | null>(null);

  useEffect(() => {
    if (props.news) {
      setPublishDate(props.news.publishDate);

      if (props.news.picture) {
        setPicture(props.news.picture?.original);
      }
    }
  }, [props.news]);

  const handleChangePublishDate = (date: Date | null) => {
    setPublishDate(date);
    props.handleChangeNews('publishDate', date);
  };

  const handleImageBs64 = (picture: string | null) => {
    props.handleChangePicture(picture);
  };

  const removeImage = () => {
    props.handleChangePicture(null);
  };

  return (
    <div className="mt-4">
      <UnderlineTitle title={Translator.trans('appModule.additional.info')} />
      <div className="information">
        <div className="col-12 col-md-4 p-0">
          <h4>{Translator.trans('news.news.edit.publishdate')}</h4>
        </div>
        <div className="col-12 col-md-5 p-0">
          <DatePickerComponent
            selected={publishDate}
            onChange={handleChangePublishDate}
            showTimeSelect
          />
        </div>
      </div>

      <div className="information">
        <div className="col-12 col-md-4 p-0">
          <h4>{Translator.trans('news.news.title.picture')}</h4>
        </div>
        <div className="col-12 col-md-5 p-0">
          {picture ? (
            <div className={'containerImg'}>
              <DeleteBadge onClick={removeImage}>
                <img src={picture} alt="artisteImage" />
              </DeleteBadge>
            </div>
          ) : (
            <ImageCropper
              labelText={Translator.trans('appModule.drag.and.drop')}
              handlePicture={handleImageBs64}
              aspect="landscape"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsContent;
