import React, { useEffect, useState } from 'react';
import DatePicker, { DatePickerProps } from 'react-datepicker';
import { currentProject } from '../../Global/currentProject';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';

interface DatePickerComponentInterface {
  labelText?: string;
  label?: string;
  noBorder?: boolean;
}

const DatePickerComponent = (
  props: DatePickerComponentInterface & DatePickerProps
) => {
  const [locale, setLocale] = useState();

  useEffect(() => {
    setLocale(loadLocale(currentProject.language.locale));
  }, []);

  const loadLocale = (locale: string) => {
    try {
      return require(`date-fns/locale/${locale}/index.js`);
    } catch (e) {
      console.error(e);
    }

    let language = locale.split('-')[0];

    const languageMap: { [key: string]: string } = {
      en: 'en-US',
      fa: 'fa-IR',
      zh: 'zh-CN',
    };

    if (Object.prototype.hasOwnProperty.call(languageMap, language)) {
      language = languageMap[language];
    }

    if (language !== locale) {
      try {
        return require(`date-fns/locale/${language}/index.js`);
      } catch (e) {
        console.error(e);
      }
    }

    return require(`date-fns/locale/en-US/index.js`);
  };

  return (
    <>
      {props.labelText && !props.label && (
        <div className={`mb-1 ${props.required && 'required'}`}>
          {props.labelText}
        </div>
      )}
      <DatePicker
        {...props}
        locale={locale}
        dateFormat={props.showTimeSelect || props.showTimeInput ? 'Pp' : 'P'}
        className={`date-picker-input ${!props.noBorder && 'border'} ${
          props.className
        }`}
        showPopperArrow={false}
        popperClassName={'date-picker-input-popper'}
      />
    </>
  );
};

export default DatePickerComponent;
