import React from 'react';
import { getMarkerIcon } from './getMarkerIcon';
import PlanCategoryModel from '../../../Models/PlanCategoryModel';
import '../Plan.css';

const PinIcon = ({
  category,
  size = 'medium',
}: {
  category?: PlanCategoryModel;
  size?: 'small' | 'medium' | 'large';
}) => (
  <div
    style={{ backgroundColor: category ? category.color : 'grey' }}
    className={`marker-icon-color ${size}`}
  >
    {size !== 'small' && getMarkerIcon(category?.type)}
  </div>
);

export default PinIcon;
