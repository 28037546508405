export type StoreTranslation = {
  id: number;
  locale: string;
  name: string | null;
  description: string | null;
  overview: string | null;
};

export default class StoreModel {
  iri: string;
  id: number;
  name: string;
  platform: 0 | 1 | 2 | 3 | 4;
  type:
    | 'accreditation'
    | 'backoffice'
    | 'import'
    | 'desk'
    | 'eshop'
    | 'store'
    | 'udesk'
    | 'mobile'
    | 'bar'
    | 'food'
    | 'other';
  translations: {
    [lang: string]: StoreTranslation;
  };

  constructor(data: any) {
    this.iri = data['@id'];
    this.id = data.id;
    this.name = data.name;
    this.platform = data.platform;
    this.type = data.type;
    this.translations = data.translations;
  }
}
