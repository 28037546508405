export type PlanCategoryType =
  | 'activities'
  | 'atm'
  | 'camping'
  | 'cashdesk'
  | 'checkin'
  | 'drinks'
  | 'emergency-exits'
  | 'entrance'
  | 'first-aid'
  | 'food'
  | 'info-point'
  | 'parking'
  | 'partner'
  | 'stage'
  | 'security'
  | 'ticketing'
  | 'toilets'
  | 'vip'
  | 'water-fountains'
  | 'wheelchair-access'
  | 'other';

export type PlanCategoryTradType = {
  locale: string;
  name: string;
};

export default class PlanCategoryModel {
  iri: string;
  id: number;
  color: string;
  type: PlanCategoryType;
  project: string;
  translations: {
    [key: string]: PlanCategoryTradType;
  };

  constructor(data: any) {
    this.iri = data['@id'];
    this.id = data.id;
    this.color = data.color;
    this.type = data.type;
    this.project = data.project;
    this.translations = data.translations;
  }
}
