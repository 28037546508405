import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Translator from '../../services/translator';
import {
  HeaderBanner,
  DragAndDrop,
  Loading,
  UnderlineTitle,
} from '@emisys/audience-sdk-ui-react';
import CompareIcon from '@mui/icons-material/Compare';
import { checkReduxResponse } from '../../services/checkReduxResponse';
import {
  getBrandingRequest,
  getPlacesListRequest,
  getPlanCategoriesRequest,
  postUploadPictureRequest,
} from '../../Redux/actions';
import { currentProject } from '../../Global/currentProject';
import { storeType } from '../../index';
import DraggableImage from './components/DraggableImage';
import ItemList from './components/ItemList';
import { get, getUrl } from '../../Redux/services';
import StoreModel from '../../Models/StoreModel';
import { NotificationManager } from '../../components/Notification/NotificationManager';
import MapPointerModel from '../../Models/MapPointerModel';
import PlanCategoryModel from '../../Models/PlanCategoryModel';
import PlaceModel from '../../Models/PlaceModel';
import './Plan.css';

type imageType = {
  id: number | null;
  original: string;
};

const Plan = () => {
  const dispatch = useDispatch();
  const [categoriesList, setCategoriesList] = useState<PlanCategoryModel[]>([]);
  const [pictureMap, setPictureMap] = useState<imageType | null>(null);
  const [loadingBranding, setLoadingBranding] = useState(false);
  const [stores, setStores] = useState<StoreModel[]>([]);
  const [places, setPlaces] = useState<PlaceModel[]>([]);
  const [selectedItem, setSelectedItem] = useState<MapPointerModel | null>(
    null
  );
  const [markersItems, setMarkersItems] = useState<MapPointerModel[]>([]);

  const brandingList = useSelector(
    (state: storeType) => state.branding.brandingList
  );
  const newUploadPicture = useSelector(
    (state: storeType) => state.uploads.newUploadPicture
  );
  const planCategories = useSelector(
    (state: storeType) => state.plan.planCategories
  );

  const placesList = useSelector((state: storeType) => state.places.placesList);

  const getMarkerItems = useCallback(() => {
    if (categoriesList.length === 0) {
      return;
    }
    get(
      getUrl(
        'content/v3/map-items.json',
        {
          'project.id': currentProject.id,
        },
        ''
      )
    )
      .then((response) => {
        const newMarkers = response.map((data: any) => {
          const marker = new MapPointerModel(data);
          marker.setDisplayName(stores, places, categoriesList);

          return marker;
        });

        setMarkersItems(newMarkers);
      })
      .catch(() => {
        NotificationManager.error(Translator.trans('plan.get.marker.error'));
      });
  }, [categoriesList, stores, places]);

  useEffect(() => {
    getMarkerItems();
  }, [getMarkerItems]);

  const getPlanCategories = useCallback(() => {
    dispatch(
      getPlanCategoriesRequest({
        'project.id': currentProject.id,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    getPlanCategories();
  }, [getPlanCategories]);

  useEffect(() => {
    if (checkReduxResponse(planCategories, 'categories')) {
      setCategoriesList(planCategories.categories);
    }
  }, [planCategories]);

  useEffect(() => {
    if (brandingList) {
      setLoadingBranding(brandingList.loading);
      if (checkReduxResponse(brandingList, 'branding')) {
        if (brandingList.branding.pictureMap) {
          setPictureMap({
            id: brandingList.branding.pictureMap.id,
            original: brandingList.branding.pictureMap.original,
          });
        }
      }
    } else {
      dispatch(getBrandingRequest(currentProject.id));
    }
  }, [brandingList, dispatch]);

  useEffect(() => {
    if (checkReduxResponse(newUploadPicture, 'uploadPicture')) {
      setPictureMap(newUploadPicture.uploadPicture);
    }
  }, [newUploadPicture]);

  useEffect(() => {
    get(
      getUrl(
        'pos/v3/stores.jsonld',
        {
          'project.id': currentProject.id,
          'type[]1': 'bar',
          'type[]2': 'food',
          'type[]3': 'store',
          visibility: 'public',
        },
        ''
      )
    )
      .then((response) => {
        const newResponse = response['hydra:member'];

        const newStores = newResponse.map(
          (store: any) => new StoreModel(store)
        );

        setStores(newStores);
      })
      .catch(() => {
        NotificationManager.error(Translator.trans('plan.get.stores.error'));
      });
  }, []);

  useEffect(() => {
    if (placesList === null) {
      dispatch(getPlacesListRequest(currentProject.id));
    } else {
      if (checkReduxResponse(placesList, 'places')) {
        setPlaces(placesList.places);
      }
    }
  }, [dispatch, placesList]);

  function onSelectFile(image: any) {
    setPictureMap({
      id: null,
      original: image.src,
    });
    dispatch(
      postUploadPictureRequest({
        type: 'PictureCommon',
        project_id: currentProject.id,
        data: image.src,
      })
    );
  }

  const onSelectItem = (item: MapPointerModel | null) => {
    if (item?.id !== selectedItem?.id) {
      setSelectedItem(item);
    } else {
      setSelectedItem(null);
    }
  };

  return (
    <div className="app-wrapper div-wrapper-title">
      <div className="headerAlign">
        <HeaderBanner
          title={Translator.trans('plan.title')}
          icon={<CompareIcon />}
        />
      </div>

      <div className="row">
        <div className="col-xl-3 col-4">
          <div
            className={`jr-card map-list-container p-3 ${
              !pictureMap?.original ? 'disabled' : ''
            }`}
          >
            <UnderlineTitle title={Translator.trans('plan.title.marker')} />
            <ItemList
              stores={stores}
              places={places}
              markersItems={markersItems}
              setMarkersItems={setMarkersItems}
              selectedItem={selectedItem}
              planCategories={categoriesList}
              onItemSelect={onSelectItem}
            />
          </div>
        </div>
        <div className="col">
          <div className="jr-card map-container p-3">
            {!loadingBranding ? (
              pictureMap?.original ? (
                <DraggableImage
                  imageUrl={pictureMap.original}
                  deleteImage={() => setPictureMap(null)}
                  selectedItem={selectedItem}
                  onItemSelect={setSelectedItem}
                  markersItems={markersItems}
                  setMarkersItems={setMarkersItems}
                  planCategories={categoriesList}
                  refreshCategories={getPlanCategories}
                  stores={stores}
                  places={places}
                />
              ) : (
                <DragAndDrop
                  name={'pictureMapTemp'}
                  labelText={Translator.trans('appModule.drag.and.drop')}
                  onChange={onSelectFile}
                />
              )
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plan;
