import React from 'react';
import PinMarker from './PinMarker';
import DragTarget from './DragTarget';
import MapPointerModel from '../../../Models/MapPointerModel';
import PlanCategoryModel from '../../../Models/PlanCategoryModel';

interface ImageCanvasProps {
  imageUrl: string;
  planCategories: PlanCategoryModel[];
  markersItems: MapPointerModel[];
  onImageClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  onRemovePosition: (id: number) => void;
  onDropListItem: (
    x: number,
    y: number,
    store: string | null,
    place: string | null
  ) => void;
  onDropCat: (cat: string, x: number, y: number) => void;
  onMovePin: (id: string, x: number, y: number) => void;
  opacity: number;
  sizeImg: {
    width: number;
    height: number;
  };
}

const ImageCanvas = (props: ImageCanvasProps) => (
  <DragTarget
    onDropListItem={props.onDropListItem}
    onDropCat={props.onDropCat}
    onPinMove={props.onMovePin}
    onImageClick={props.onImageClick}
    sizeImg={props.sizeImg}
  >
    <img
      src={props.imageUrl}
      alt="Background"
      style={{ opacity: props.opacity / 100 }}
    />
    {props.markersItems
      .filter((item) => item.offset_x !== 0 && item.offset_y !== 0)
      .map((marker, index) => (
        <PinMarker
          key={index}
          planCategories={props.planCategories}
          marker={marker}
          onRemove={props.onRemovePosition}
          sizeImg={props.sizeImg}
        />
      ))}
  </DragTarget>
);

export default ImageCanvas;
