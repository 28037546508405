import Translator from '../services/translator';
import { getNameLanguage } from '../services/GetNameLanguage';
import { currentProject } from '../Global/currentProject';

export const pagesConstant = {
  home: '/home',
  profile: '/profile',
  lineUp: '/lineUp',
  places: '/places',
  plan: {
    home: '/plan',
    categories: '/plan-categories/',
  },
  notifications: '/notifications',
  config: '/config',
  branding: '/branding',
  sessiontracks: '/sessiontracks',
  article: {
    home: '/articles',
    edit: '/edit-article/',
    add: '/new-article',
  },
  news: {
    home: '/news',
    edit: '/edit-news/',
    add: '/new-news',
  },
  performers: {
    home: '/performers',
    edit: '/edit-performer/',
    add: '/new-performer',
  },
  sessions: {
    home: '/sessions',
    edit: '/edit-session/',
    add: '/new-session',
  },
  events: {
    current: '/event',
    list: '/events/list',
  },
};

export interface selectItemsInterface {
  value: string | number;
  label: string;
}

export const eventTypeList: readonly selectItemsInterface[] = [
  {
    value: 'concert',
    label: Translator.trans('project.eventType.concert'),
  },
  {
    value: 'festival',
    label: Translator.trans('project.eventType.festival'),
  },
  {
    value: 'webinar',
    label: Translator.trans('project.eventType.webinar'),
  },
  {
    value: 'sport',
    label: Translator.trans('project.eventType.sport'),
  },
  {
    value: 'culture',
    label: Translator.trans('project.eventType.culture'),
  },
  {
    value: 'professional',
    label: Translator.trans('project.eventType.professional'),
  },
  {
    value: 'private',
    label: Translator.trans('project.eventType.private'),
  },
  {
    value: 'workshop',
    label: Translator.trans('project.eventType.workshop'),
  },
  {
    value: 'charity',
    label: Translator.trans('project.eventType.charity'),
  },
  {
    value: 'test',
    label: Translator.trans('project.eventType.test'),
  },
  {
    value: 'other',
    label: Translator.trans('project.eventType.other'),
  },
];

export const getEventType = (currentType: string) => {
  return eventTypeList.find((data) => data.value === currentType);
};

export const languagesList: selectItemsInterface[] = [
  {
    value: 'fr',
    label: getNameLanguage(currentProject.language.locale, 'fr'),
  },
  {
    value: 'en_US',
    label: getNameLanguage(currentProject.language.locale, 'en_US'),
  },
  {
    value: 'nl',
    label: getNameLanguage(currentProject.language.locale, 'nl'),
  },
  {
    value: 'fr_CA',
    label: getNameLanguage(currentProject.language.locale, 'fr_CA'),
  },
];

export const getLanguage = (language: string) => {
  let tmpLang = languagesList.find((data) => data.value === language);
  if (tmpLang) {
    return tmpLang;
  } else {
    tmpLang = languagesList.find(
      (data) => data.value.toString().slice(0, 2) === language.slice(0, 2)
    );
    if (tmpLang) {
      return tmpLang;
    } else {
      return languagesList[0];
    }
  }
};
