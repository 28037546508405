import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Translator from '../../../services/translator';
import SessionContent from '../SessionContent/SessionContent';
import {
  getPerformersListRequest,
  postProgramSessionsRequest,
  postSessionTranslationRequest,
  postSessionPlacesRequest,
  postSessionPerformersRequest,
  clearPerformer,
  getProgramsListRequest,
} from '../../../Redux/actions';
import { NotificationManager } from '../../../components/Notification/NotificationManager';
import { currentProject } from '../../../Global/currentProject';
import { pagesConstant } from '../../../Constants/Constant';
import { HeaderBanner, UnderlineTitle } from '@emisys/audience-sdk-ui-react';
import QueryBuilder from '@mui/icons-material/QueryBuilder';
import { checkReduxResponse } from '../../../services/checkReduxResponse';
import { storeType } from '../../../index';
import ButtonsSaveModal from '../../../components/Buttons/ButtonsSaveModal';
import AccordionTranslation from '../../../components/Accordion/AccordionTranslation';
import SessionTranslations from '../SessionContent/SessionTranslations';

const SessionCreation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadingSend, setLoadingSend] = useState(false);

  const newPlace = useSelector((state: storeType) => state.places.newPlace);
  const newSession = useSelector(
    (state: storeType) => state.programs.newSession
  );
  const newPerformer = useSelector(
    (state: storeType) => state.performers.newPerformer
  );
  const programsList = useSelector(
    (state: storeType) => state.programs.programsList
  );

  const performers: any = useRef([]);
  const places: any = useRef([]);
  const translationSessions: any = useRef([]);
  const session: any = useRef({
    idProject: currentProject.id,
    title: '',
    description: '',
    locale: '',
    liveStartDateTimeUTC: '',
    liveEndDateTimeUTC: '',
    confirmed: false,
    highlighted: false,
    signedLanguages: false,
    track: null,
    timeToBeConfirmed: false,
    duration: null,
  });

  useEffect(() => {
    if (
      currentProject.allowedLanguages &&
      translationSessions.current.length === 0
    ) {
      translationSessions.current = currentProject.allowedLanguages.map(
        (language) => ({
          locale: language,
          description: '',
        })
      );
    }
  }, []);

  useEffect(() => {
    if (newSession) {
      setLoadingSend(newSession.loading);
      if (checkReduxResponse(newSession, 'session')) {
        if (performers.current.length !== 0) {
          dispatch(
            postSessionPerformersRequest(newSession.session.id, {
              performers: performers.current,
            })
          );
        }
        if (places.current.length !== 0) {
          dispatch(
            postSessionPlacesRequest(newSession.session.id, {
              places: places.current,
            })
          );
        }
        dispatch(
          postSessionTranslationRequest(newSession.session.id, {
            translations: translationSessions.current,
          })
        );

        NotificationManager.success(
          Translator.trans('sessions.message.success.added')
        );
        navigate(pagesConstant.sessions.home);
      }
    }
  }, [dispatch, navigate, newSession]);

  useEffect(() => {
    if (newPlace) {
      setLoadingSend(newPlace.loading);
      if (checkReduxResponse(newPlace, 'place')) {
        places.current.push(newPlace.place.id);
        NotificationManager.success(
          Translator.trans('places.message.success.added')
        );
      }
    }
  }, [newPlace]);

  useEffect(() => {
    if (newPerformer) {
      setLoadingSend(newPerformer.loading);
      if (checkReduxResponse(newPerformer, 'performer')) {
        performers.current.push(newPerformer.performer.id);
        NotificationManager.success(
          Translator.trans('performers.message.success.added')
        );
        dispatch(
          getPerformersListRequest(currentProject.id, {
            order: 'name:ASC',
          })
        );
        dispatch(clearPerformer());
      }
    }
  }, [dispatch, newPerformer]);

  useEffect(() => {
    if (!programsList) {
      dispatch(getProgramsListRequest(currentProject.id));
    }
  }, [programsList, dispatch]);

  const handleChangeTranslation = (
    name: string,
    value: string,
    locale: string
  ) => {
    let objIndex = translationSessions.current.findIndex(
      (item: any) => item.locale === locale
    );
    if (objIndex > -1) {
      translationSessions.current[objIndex][name] = value;
    }
  };

  const handleChangeSession = (name: string, data: any) => {
    if (name === 'performers') {
      performers.current = data;
    } else if (name === 'places') {
      places.current = data;
    } else {
      session.current[name] = data;
    }
  };

  const handleSubmit = () => {
    if (session.current.title === '' || session.current.title === null) {
      NotificationManager.warning(Translator.trans('message.title.missing'));
    } else {
      if (programsList && programsList.programs.length > 0) {
        dispatch(
          postProgramSessionsRequest(
            programsList.programs[0].id,
            session.current
          )
        );
      }
    }
  };

  return (
    <div className="app-wrapper div-wrapper-title">
      <div className="headerAlign">
        <HeaderBanner
          title={Translator.trans('sessions.add.subtitle')}
          icon={<QueryBuilder />}
        />
      </div>
      <div className="row">
        <div className="col-12">
          <div className="jr-card">
            <SessionContent handleChangeSession={handleChangeSession} />

            <div className="mt-4">
              <UnderlineTitle
                title={Translator.trans('sessions.title.description')}
              />
              {currentProject.allowedLanguages.map((language) => (
                <AccordionTranslation key={language} language={language}>
                  <SessionTranslations
                    handleChangeTranslation={handleChangeTranslation}
                    language={language}
                  />
                </AccordionTranslation>
              ))}
            </div>

            <ButtonsSaveModal
              onClose={() => navigate(pagesConstant.sessions.home)}
              onSubmit={handleSubmit}
              isLoading={loadingSend}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionCreation;
