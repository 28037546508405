import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import GroupIcon from '@mui/icons-material/Group';
import Notifications from '@mui/icons-material/NotificationsActive';
import PhonelinkSetup from '@mui/icons-material/PhonelinkSetup';
import QueryBuilder from '@mui/icons-material/QueryBuilder';
import Bulb from '@mui/icons-material/EmojiObjects';
import Place from '@mui/icons-material/Place';
import News from '@mui/icons-material/MenuBook';
import LibraryBooks from '@mui/icons-material/LibraryBooks';
import Translator from '../../services/translator';
import { getAppMobileInfoRequest } from '../../Redux/actions';
import { HomeCard, HeaderBanner } from '@emisys/audience-sdk-ui-react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { currentProject } from '../../Global/currentProject';
// import { storeType } from '../../index';
// import { checkReduxResponse } from '../../services/checkReduxResponse';
import { pagesConstant } from '../../Constants/Constant';

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [appMobileUrlWebapp, setAppMobileUrlWebapp] = useState('');

  // const appMobileList = useSelector(
  //   (state: storeType) => state.appMobile.appMobileList
  // );

  useEffect(() => {
    dispatch(getAppMobileInfoRequest(currentProject.id));
  }, [dispatch]);

  // useEffect(() => {
  //   if (checkReduxResponse(appMobileList, 'appMobile')) {
  //     setAppMobileUrlWebapp(appMobileList.appMobile.appMobileUrlWebapp);
  //   }
  // }, [appMobileList]);

  const handleClick = (param: string) => {
    navigate(param);
  };

  return (
    <div className="app-wrapper div-wrapper-title">
      <div className="headerAlign">
        <HeaderBanner
          title={Translator.trans('sidenav.title.dashboard')}
          icon={<DashboardIcon />}
        />
      </div>
      <div className="row">
        <div className="col-lg-6 col-sm-12 news">
          <HomeCard
            icon={<News />}
            title={Translator.trans('home.card.title.news')}
            subtitle={Translator.trans('home.card.content.news')}
            onClick={() => handleClick(pagesConstant.news.home)}
          />
        </div>
        <div className="col-lg-6 col-sm-12 articles">
          <HomeCard
            icon={<LibraryBooks />}
            title={Translator.trans('home.card.title.articles')}
            subtitle={Translator.trans('home.card.content.articles')}
            onClick={() => handleClick(pagesConstant.article.home)}
          />
        </div>
        <div className="col-lg-6 col-sm-12 performers">
          <HomeCard
            icon={<GroupIcon />}
            title={Translator.trans('home.card.title.performers')}
            subtitle={Translator.trans('home.card.content.performers')}
            onClick={() => handleClick(pagesConstant.performers.home)}
          />
        </div>
        <div className="col-lg-6 col-sm-12 sessions">
          <HomeCard
            icon={<QueryBuilder />}
            title={Translator.trans('home.card.title.sessions')}
            subtitle={Translator.trans('home.card.content.sessions')}
            onClick={() => handleClick(pagesConstant.sessions.home)}
          />
        </div>
        <div className="col-lg-6 col-sm-12 places">
          <HomeCard
            icon={<Place />}
            title={Translator.trans('home.card.title.places')}
            subtitle={Translator.trans('home.card.content.places')}
            onClick={() => handleClick(pagesConstant.places)}
          />
        </div>
        <div className="col-lg-6 col-sm-12 sessiontracks">
          <HomeCard
            icon={<Bulb />}
            title={Translator.trans('home.card.title.sessiontracks')}
            subtitle={Translator.trans('home.card.content.sessiontracks')}
            onClick={() => handleClick(pagesConstant.sessiontracks)}
          />
        </div>
        <div className="col-lg-6 col-sm-12 notifications">
          <HomeCard
            icon={<Notifications />}
            title={Translator.trans('home.card.title.notifications')}
            subtitle={Translator.trans('home.card.content.notifications')}
            onClick={() => handleClick(pagesConstant.notifications)}
          />
        </div>
        <div className="col-lg-6 col-sm-12 mobileapp">
          <HomeCard
            icon={<PhonelinkSetup />}
            title={Translator.trans('home.card.title.mobile.app')}
            subtitle={Translator.trans('home.card.content.mobile.app')}
            onClick={() => handleClick(pagesConstant.config)}
          />
        </div>
        {/*<div className="col-lg-6 col-sm-12 appMobile">*/}
        {/*  <HomeCard*/}
        {/*    icon={<LinkIcon />}*/}
        {/*    title={Translator.trans('home.card.title.appMobile')}*/}
        {/*    subtitle={Translator.trans('home.card.content.appMobile')}*/}
        {/*    onClick={() => alert(appMobileUrlWebapp)}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default Home;
