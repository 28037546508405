import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { arrayMoveImmutable } from 'array-move';
import {
  clearPlaces,
  getPlacesListRequest,
  putPlacesPositionRequest,
} from '../../../Redux/actions';
import { NotificationManager } from '../../../components/Notification/NotificationManager';
import Translator from '../../../services/translator';
import { Loading } from '@emisys/audience-sdk-ui-react';
import { currentProject } from '../../../Global/currentProject';
import { checkReduxResponse } from '../../../services/checkReduxResponse';
import {
  Grid,
  PagingPanel,
  Table,
  TableFilterRow,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  hideSortingButtons,
  rowPerTablePage,
} from '../../../components/Table/Constants/Constant';
import SortableTable from '../../../components/Table/Sortable/SortableTable/SortableTable';
import SortableRow from '../../../components/Table/Sortable/SortableRow/SortableRow';
import TableContainer from '../../../components/Table/TableContainer/TableContainer';
import NoData from '../../../components/Table/NoData/NoData';
import PagingTable from '../../../components/Table/Paging/PagingTable';
import FilterPlaceCell from '../../../components/Table/Cells/Places/FilterPlaceCell';
import ColumnCell from '../../../components/Table/Columns/ColumnCell';
import SortLabel from '../../../components/Table/Sorting/SortLabel';
import { sortingColumnExtensions } from '../../../components/Table/Sorting/SortingExtensions';
import { filteringColumnExtensions } from '../../../components/Table/Filters/FilterExtensions';
import {
  columnsPlaces,
  tableColumnExtensionsPlaces,
} from '../../../components/Table/Columns/ShowColumns';
import PlacesCell from '../../../components/Table/Cells/Places/PlacesCell';
import { getCurrentTranslation } from '../../../services/GetCurrentTranslation';
import { storeType } from '../../../index';
import PlaceModel from '../../../Models/PlaceModel';

const PlacesTable = () => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState<any[]>([]);
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const locale = currentProject.language.locale;

  const placesList = useSelector((state: storeType) => state.places.placesList);
  const oldPlace = useSelector((state: storeType) => state.places.oldPlace);
  const translationPlace = useSelector(
    (state: storeType) => state.places.translationPlace
  );
  const updatedTranslationPlace = useSelector(
    (state: storeType) => state.places.updatedTranslationPlace
  );
  const updatedPlacePosition = useSelector(
    (state: storeType) => state.places.updatedPlacePosition
  );
  const newPlace = useSelector((state: storeType) => state.places.newPlace);

  const getPlacesList = useCallback(
    (projectId: number) => {
      dispatch(getPlacesListRequest(projectId));
    },
    [dispatch]
  );

  useEffect(() => {
    getPlacesList(currentProject.id);
  }, [getPlacesList]);

  useEffect(() => {
    if (placesList) {
      setLoading(placesList.loading);

      if (checkReduxResponse(placesList, 'places')) {
        let newRows = placesList.places.map((place: PlaceModel) => {
          return {
            id: place.id,
            name: getCurrentTranslation(place.translations, locale).name ?? '',
            place: place,
          };
        });
        setRows(newRows);
      }
    }
  }, [locale, placesList]);

  useEffect(() => {
    if (checkReduxResponse(newPlace, 'place')) {
      NotificationManager.success(
        Translator.trans('places.message.success.added')
      );
      dispatch(clearPlaces());
    }
  }, [dispatch, newPlace]);

  useEffect(() => {
    if (checkReduxResponse(oldPlace, 'place')) {
      getPlacesList(currentProject.id);
      NotificationManager.success(
        Translator.trans('places.message.success.deleted')
      );
      dispatch(clearPlaces());
    }
  }, [dispatch, getPlacesList, oldPlace]);

  useEffect(() => {
    if (checkReduxResponse(translationPlace, 'place')) {
      getPlacesList(currentProject.id);
    }
  }, [dispatch, getPlacesList, translationPlace]);

  useEffect(() => {
    if (checkReduxResponse(updatedTranslationPlace, 'place')) {
      getPlacesList(currentProject.id);
      NotificationManager.success(
        Translator.trans('places.message.success.updated')
      );
      dispatch(clearPlaces());
    }
  }, [dispatch, getPlacesList, updatedTranslationPlace]);

  useEffect(() => {
    if (checkReduxResponse(updatedPlacePosition, 'place')) {
      getPlacesList(currentProject.id);
      NotificationManager.success(
        Translator.trans('cms.message.success.position.updated')
      );
      dispatch(clearPlaces());
    }
  }, [dispatch, getPlacesList, updatedPlacePosition]);

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (oldIndex !== newIndex) {
      let array: any[] = arrayMoveImmutable(rows, oldIndex, newIndex);
      setRows(array);
      dispatch(
        putPlacesPositionRequest({
          id: array[newIndex].id,
          position: newIndex,
        })
      );
    }
  };

  const setCurrentFilters = (filter: any) => {
    let newFilter = filter.filter(
      (data: any) => !(data.value === '' || data.value === 'all')
    );
    setFilters(newFilter);
  };

  return (
    <div>
      <Grid rows={rows} columns={columnsPlaces}>
        <PagingState />
        <FilteringState onFiltersChange={setCurrentFilters} filters={filters} />
        <SortingState columnExtensions={hideSortingButtons} />
        <IntegratedSorting columnExtensions={sortingColumnExtensions} />
        <IntegratedFiltering columnExtensions={filteringColumnExtensions} />
        <IntegratedPaging />
        <Table
          columnExtensions={tableColumnExtensionsPlaces}
          bodyComponent={(props) => (
            <SortableTable props={props} onSortEnd={onSortEnd} />
          )}
          rowComponent={(props) => <SortableRow props={props} rows={rows} />}
          cellComponent={PlacesCell}
          tableComponent={TableContainer}
          noDataCellComponent={NoData}
        />
        <TableHeaderRow
          showSortingControls
          sortLabelComponent={SortLabel}
          cellComponent={ColumnCell}
        />
        <TableFilterRow cellComponent={FilterPlaceCell} />
        <PagingPanel
          pageSizes={rowPerTablePage}
          containerComponent={PagingTable}
        />
      </Grid>
      {loading && <Loading />}
    </div>
  );
};

export default PlacesTable;
