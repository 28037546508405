import React from 'react';
import PlanCategoryModel from '../../../Models/PlanCategoryModel';
import PinIcon from './PinIcon';
import '../Plan.css';

interface DraggableItemProps {
  item: PlanCategoryModel;
}

export function DraggableCategory({ item }: DraggableItemProps) {
  const [dragOver, setDragOver] = React.useState(false);

  const handleDragStart = (e: React.DragEvent<HTMLLIElement>) => {
    setDragOver(true);
    e.dataTransfer.setData('cat-type', item.iri);
  };

  return (
    <li
      draggable
      onDragStart={handleDragStart}
      onDragEnd={() => setDragOver(false)}
      className={`draggable-category`}
      style={{ opacity: dragOver ? 0.4 : 1 }}
    >
      <PinIcon category={item} />
    </li>
  );
}
